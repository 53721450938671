@import '../../../global.module';

.normal{
    @include boxflex();
    //flex-direction: column;
    justify-content: center;
    :global{

    }
}

.normalM{
    @include boxflex();
    //flex-direction: column;
    justify-content: center;
    width: 100%;
    :global{

    }
}