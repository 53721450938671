@import '../../global.module';

// PC端的样式
.header{
    background: $bgTran;
    height: setnum(108);
    @include boxflex();
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    
    :global{
        .logos{
            margin-left: setnum(80);
            width: setnum(60);
            height: setnum(60);
            line-height: setnum(60);
            font-size: setnum(16);
            @include boxflex();
            cursor: pointer;
            img:first-child{
                width: setnum(60);
                height: setnum(60);
            }
            img:last-child{
                visibility: hidden;
                margin-left: setnum(16);
                //width: setnum(106);
                height: setnum(30);
            }
        }
        .logos:hover{
            img:last-child{
                visibility: visible;
            }
        }
        .nav{
            @include boxflex();
            margin-right: setnum(80);
            .links{
                @include boxflex();
                .defaults{
                    margin-right: setnum(40);
                    border-bottom: setnum(2) solid $bgTran;
                    padding-bottom: setnum(8);
                    .txtBox{
                        height: setnum(22);
                        min-height: 22px;
                        overflow: hidden;
                        cursor: pointer;
                        .tetContent{
                            transition: all 0.5s;
                            transform: translate(0%, 0%);
                            div{
                                display: block;
                                font-size: setnum(18);
                                cursor:pointer;
                                color: #1A1A1A;
                                margin-bottom: setnum(10);
                            }
                            div:last-child{
                                margin-bottom: setnum(10);
                            }
                        }
                        .tetContent:hover{
                            transform: translate(0%, -58%);
                            div{
                                margin-bottom: setnum(10);
                            }
                            div:last-child{
                                margin-bottom: setnum(10);
                            }
                        }
                    }
                }
                .active {
                    color: #1A1A1A;
                    border-bottom: setnum(2) solid #1A1A1A;
                    padding-bottom: setnum(8);
                    font-weight: bold;
                }
            }
            .localesTab{
                //padding-top: setnum(5);
            }

        }
        
    }
}
// 称动端的样式
.headerM{
    background: $bgTran;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9999;
    :global{
        .logos{
            width: 100%;
            margin-bottom: setnum(34);
            height: setnum(76);
            line-height: setnum(76);
            font-size: setnum(16);
            @include boxflex();
            cursor: pointer;
            img{
                margin-left: setnum(24);
                width: setnum(44);
                height: setnum(44);
            }
            /* 按钮 start */
            .navBtn{
                margin-right: setnum(24);
                width: setnum(32);
                height: setnum(32);
                @include boxflex();
                justify-content: center;
                align-items: center;
                img{
                    width: setnum(32);
                    height: setnum(32);
                }
            }
            /* 按钮 end */
        }

        .nav{
            @include boxflex();
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            padding: 0 setnum(24);
            .links{
                width: 100%;
                @include boxflex();
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                //margin-bottom: setnum(62);
                .defaults{
                    margin-right: setnum(40);
                    border-bottom: setnum(2) solid $bgTran;
                    padding-bottom: setnum(8);
                    margin-bottom: setnum(40);
                    .txtBox{
                        height: setnum(22);
                        overflow: hidden;
                        cursor: pointer;
                        .tetContent{
                            transition: all 0.5s;
                            transform: translate(0%, 0%);
                            div{
                                font-size: setnum(20);
                                cursor:pointer;
                                color: #1A1A1A;
                            }
                        }
                        .tetContent:hover{
                            transform: translate(0%, -50%);
                        }
                    }
                }
                .active {
                    color: #1A1A1A;
                    border-bottom: setnum(2) solid #1A1A1A;
                    padding-bottom: setnum(8);
                    font-weight: bold;
                }
            }
            .share{
                width: setnum(80);
                height: setnum(32);
                @include boxflex();
                margin-bottom: setnum(62);
                i{
                    display: inline-block;
                    width: setnum(32);
                    height: setnum(32);
                    cursor: pointer;
                    img{
                        width: setnum(32);
                        height: setnum(32);
                    }
                }
            }
        }

    }
}

