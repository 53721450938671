@import '../../global.module';

.normalMax{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            margin-bottom: setnum(80);
            .content{
                .rowb{
                    padding-left: setnum(825);
                    .textanimate{
                        width: setnum(1197);
                        height: setnum($hMax);
                        margin-left: setnum(-8);
                        margin-bottom: setnum(50);
                    }
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: setnum(72);
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }
        
                    }
                }
            }
           
            .list{
                @include boxflex();
                justify-content: center;
                .cul{
                    display: grid;
                    grid-template-columns: 25% 25% 25% 25%;
                    .cli{
                        width: setnum(300);
                        margin: auto auto setnum(56) auto;
                        .libox{
                            @include boxflex();
                            flex-direction: column;
                            justify-content: center;
                            .icon{
                                width: setnum(80);
                                height: setnum(80);
                                margin-bottom: setnum(16);
                                img{
                                    width: setnum(80);
                                    height: setnum(80);
                                }
                            }
                            .h5zh{
                                height: setnum(30);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(24);
                                line-height: setnum(30);
                                text-align: center;
                                color: #1A1A1A;
                                margin-bottom: setnum(19);
                            }

                            .h5en{
                                height: setnum(30);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(24);
                                line-height: setnum(30);
                                text-align: center;
                                color: #1A1A1A;
                                margin-bottom: setnum(19);
                                white-space:nowrap;
                            }

                            .innerTexts{
                                p{
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(16);
                                    line-height: setnum(32);
                                    text-align: center;
                                    color: #1A1A1A;
                                    opacity: 0.6;
                                    @include whiteSpaceNowrap();
                                }
                            }

                        }
                    }
                }
            }
        }
        .cardb{
            // background: red;
            .contentb{
                .binfor{
                    padding-left: setnum(360);
                    .textanimate{
                        width: setnum(2904);
                        height: setnum($hMax);
                        margin-left: setnum(-42);
                        margin-bottom: setnum(50);
                    }
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: setnum(72);
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }
        
                    }
                }
            }
            .taba{
                @include boxflex();
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-bottom: setnum(80);
                .lcontent{
                    width: setnum(480+815-220);
                    height: setnum(352 + 324);
                    position: relative;
                    .lcarditem:first-child{
                        width: setnum(420);
                        height: setnum(534);
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(420*2);
                            height: setnum(534);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(420);
                                height: setnum(534);
                            }
                        }
                    }
                    .lcarditem:nth-child(2){
                        width: setnum(530);
                        height: setnum(352);
                        position: absolute;
                        right: setnum(200);
                        bottom: 0;
                        z-index: 2;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(530*2);
                            height: setnum(352);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(530);
                                height: setnum(352);
                            }
                        }
                    }
                    .lcarditem:last-child{
                        width: setnum(480);
                        height: setnum(270);
                        position: absolute;
                        top: setnum(170-72);
                        right: 0;
                        z-index: 3;
                        overflow: hidden;

                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(480*2);
                            height: setnum(270);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(480);
                                height: setnum(270);
                            }
                        }
                    }
                    
                }
                .rcontent{
                    margin-top: setnum(-175);
                    margin-left: setnum(60);
                    width: setnum(346);
                    .rdesc{
                        width: setnum(346);
                        margin-bottom: setnum(40);
                        p{
                            width: setnum(346);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            //text-align: justify;
                            color: #1A1A1A;
                        }
                    }
                    .rbtn{
                        @include boxflex();
                        justify-content: flex-start;
                        
                        .tfirst, .tnext{
                            @include boxflex();
                            justify-content: center;
                            width: setnum(58);
                            height: setnum(58);
                            cursor: pointer;
                            transition: all 0.5s;
                        }
                        .tfirst{
                            background: #FFFFFF;
                            opacity: 0.3;
                            border: setnum(1) solid #1A1A1A;
                            margin-right: setnum(12);
                        }
                        .tnext{
                            background: #FFFFFF;
                            border: setnum(1) solid #1A1A1A;
                            margin-left: setnum(12);
                        }
                        .tfirst > img, .tnext > img{
                            width: setnum(7.41);
                            height: setnum(12);
                        }

                        .tNextFirstActionWith{
                            background: #FFFFFF;
                        }
                        .tNextFirstAction{
                            opacity: 1 !important;
                            background: #1A1A1A;
                            border: setnum(1) solid #1A1A1A;
                        }
                    }
                }
            }

            .tabb{
                @include boxflex();
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-bottom: setnum(80);
                .rcontent{
                    margin-top: setnum(150);
                    margin-right: setnum(180);
                    width: setnum(346);
                    .rdesc{
                        width: setnum(346);
                        margin-bottom: setnum(40);
                        p{
                            width: setnum(346);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            //text-align: justify;
                            color: #1A1A1A;
                        }
                    }
                    .rbtn{
                        @include boxflex();
                        justify-content: flex-start;
                        
                        .tfirst, .tnext{
                            @include boxflex();
                            justify-content: center;
                            width: setnum(58);
                            height: setnum(58);
                            cursor: pointer;
                            transition: all 0.5s;
                        }
                        .tfirst{
                            background: #FFFFFF;
                            opacity: 0.3;
                            border: setnum(1) solid #1A1A1A;
                            margin-right: setnum(12);
                        }
                        .tnext{
                            background: #FFFFFF;
                            border: setnum(1) solid #1A1A1A;
                            margin-left: setnum(12);
                        }
                        .tfirst > img, .tnext > img{
                            width: setnum(7.41);
                            height: setnum(12);
                        }
                        .tNextFirstActionWith{
                            background: #FFFFFF;
                        }
                        .tNextFirstAction{
                            opacity: 1 !important;
                            background: #1A1A1A;
                            border: setnum(1) solid #1A1A1A;
                        }
                    }
                }
                .lcontent{
                    width: setnum(250+924-220);
                    height: setnum(514 + 160);
                    position: relative;
                    .lcarditem:first-child{
                        width: setnum(250);
                        height: setnum(250);
                        position: absolute;
                        top: setnum(188-80);
                        left: 0;
                        z-index: 2;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(250*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(250);
                                height: setnum(250);
                            }
                        }
                    }
                    .lcarditem:nth-child(2){
                        width: setnum(530);
                        height: setnum(352);
                        position: absolute;
                        left: setnum(79);
                        bottom: 0;
                        z-index: 1;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(530*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(530);
                                height: setnum(352);
                            }
                        }
                    }
                    .lcarditem:last-child{
                        background: pink;
                        width: setnum(420);
                        height: setnum(534);
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 2;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(420*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(420);
                                height: setnum(534);
                            }
                        }
                    }
                    
                }
            }
        }
        .cardc{
            background: #F7F5F4;
            padding: setnum(56) setnum(360) setnum(120) setnum(360);
            .contentc{
                margin-bottom: setnum(72);
                .cinfor{
                    .textanimate{
                        width: setnum(1078);
                        height: setnum($hMax);
                        margin-bottom: setnum(50);
                    }
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }
        
                    }
                }
            }
            .tabs{
                .tag{
                    .tul{
                        @include boxflex();
                        margin-bottom: setnum(58);
                        .tli{
                            //width: setnum(160);
                            width: setnum(160);
                            box-sizing: border-box;
                            @include boxflex();
                            flex-direction: column;
                            justify-content: center;
                            padding: setnum(24) 0 setnum(31) 0;
                            background: transparent;
                            position: relative;
                            cursor: pointer;
                            //transition: all 0.5s;
                            .icon{
                                width: setnum(64);
                                height: setnum(64);
                                margin-bottom: setnum(16);
                                img{
                                    width: setnum(64);
                                    height: setnum(64);
                                }
                            }
                            p{
                                height: setnum(28);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(20);
                                line-height: setnum(28);
                                text-align: center;
                                color: #1A1A1A;
                                white-space:nowrap;
                            }
                            .triangle{
                                position: absolute;
                                left: 41%;
                                bottom: setnum(-5);
                                display: none;
                                width: 0;
                                height: 0;
                                border-width: setnum(9);
                                border-style: solid;
                                border-color: transparent transparent transparent transparent;
                                transform: rotate(-135deg);
                                transition: all 0.7s;
                                opacity: 0;
                            }
                            &:hover{
                                background: #FFF;
                                .triangle{
                                    display: inline-block;
                                    opacity: 1;
                                    border-color: #FFF #FFF #FFF #FFF;
                                }
                            }

                        }
                        .tliactive {
                            background: #FFF;
                            .triangle{
                                display: inline-block;
                                opacity: 1;
                                border-color: #FFF #FFF #FFF #FFF;
                            }
                        }
                    }
                }
                .table{
                    table{
                        width: 100%;
                        border-collapse: collapse;
                        background: #FFF;
                        thead{
                            tr{
                                
                                th{
                                    text-align: left;
                                    padding-left: setnum(60);
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(14);
                                    color: #1A1A1A;
                                    opacity: 0.3;
                                    border-bottom: setnum(1) solid #f4f4f4;
                                    height: setnum(84);
                                }
                            }
                        }
                        tbody{
                            tr{
                                td{
                                    //width: setnum(400);
                                    width: 33.333%;
                                    text-align: left;
                                    padding-left: setnum(60);
                                    height: setnum(101);
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: setnum(16);
                                    color: #1A1A1A;
                                    opacity: 0.8;
                                    cursor: pointer;
                                }
                                td:last-child{
                                    .jobdetails{
                                        @include boxflex();
                                        .arrowIcon{
                                            visibility: hidden;
                                            margin-right: setnum(36);
                                            width: setnum(24);
                                        }
                                    }
                                }
                            }
                            .tractive:hover{
                                td{
                                    font-weight: 500;
                                    opacity: 1;
                                    
                                }
                                td:last-child{
                                    .jobdetails{
                                        @include boxflex();
                                        .arrowIcon{
                                            display: inline-block;
                                            visibility: visible;
                                            margin-right: setnum(36);
                                            width: setnum(24);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.normal{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            margin-bottom: setnum(80);
            .content{
                .rowb{
                    padding-left: setnum(602);
                    .textanimate{
                        width: setnum(879.22);
                        height: setnum(134.01);
                        padding-left: setnum(15);
                        margin-bottom: setnum(50);
                    }
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: setnum(72);
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }

                    }
                }
            }

            .list{
                @include boxflex();
                justify-content: center;
                .cul{
                    display: grid;
                    grid-template-columns: 25% 25% 25% 25%;
                    .cli{
                        width: setnum(300);
                        margin: auto auto setnum(56) auto;
                        .libox{
                            @include boxflex();
                            flex-direction: column;
                            justify-content: center;
                            .icon{
                                width: setnum(80);
                                height: setnum(80);
                                margin-bottom: setnum(16);
                                img{
                                    width: setnum(80);
                                    height: setnum(80);
                                }
                            }
                            .h5zh{
                                height: setnum(30);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(24);
                                line-height: setnum(30);
                                text-align: center;
                                color: #1A1A1A;
                                margin-bottom: setnum(19);
                            }

                            .h5en{
                                height: setnum(30);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(24);
                                line-height: setnum(30);
                                text-align: center;
                                color: #1A1A1A;
                                margin-bottom: setnum(19);
                                white-space:nowrap;
                            }

                            .innerTexts{
                                p{
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(16);
                                    line-height: setnum(32);
                                    text-align: center;
                                    color: #1A1A1A;
                                    opacity: 0.6;
                                    @include whiteSpaceNowrap();
                                }
                            }

                        }
                    }
                }
            }
        }
        .cardb{
            // background: red;
            .contentb{
                .binfor{
                    padding-left: setnum(360);
                    .textanimate{
                        width: setnum(2169.4);
                        height: setnum(134.01);
                        margin-bottom: setnum(50);
                    }
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: setnum(72);
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }

                    }
                }
            }
            .taba{
                @include boxflex();
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-bottom: setnum(80);
                .lcontent{
                    width: setnum(586+409-80);
                    height: setnum(300 + 362 - 72);
                    position: relative;
                    .lcarditem:first-child{
                        width: setnum(358);
                        height: setnum(455);
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(358*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(358);
                                height: setnum(455);
                            }
                        }
                    }
                    .lcarditem:nth-child(2){
                        width: setnum(452);
                        height: setnum(300);
                        position: absolute;
                        right: setnum(158);
                        bottom: 0;
                        z-index: 2;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(452*2);
                            height: setnum(300);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(452);
                                height: setnum(300);
                            }
                        }
                    }
                    .lcarditem:last-child{
                        width: setnum(409);
                        height: setnum(230);
                        position: absolute;
                        top: setnum(170-72);
                        right: 0;
                        z-index: 3;
                        overflow: hidden;

                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(409*2);
                            height: setnum(230);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(409);
                                height: setnum(230);
                            }
                        }
                    }

                }
                .rcontent{
                    margin-top: setnum(-175);
                    margin-left: setnum(60);
                    width: setnum(305);
                    .rdesc{
                        width: setnum(305);
                        margin-bottom: setnum(40);
                        p{
                            width: setnum(305);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            //text-align: justify;
                            color: #1A1A1A;
                        }
                    }
                    .rbtn{
                        @include boxflex();
                        justify-content: flex-start;

                        .tfirst, .tnext{
                            @include boxflex();
                            justify-content: center;
                            width: setnum(58);
                            height: setnum(58);
                            cursor: pointer;
                            transition: all 0.5s;
                        }
                        .tfirst{
                            background: #FFFFFF;
                            opacity: 0.3;
                            border: setnum(1) solid #1A1A1A;
                            margin-right: setnum(12);
                        }
                        .tnext{
                            background: #FFFFFF;
                            border: setnum(1) solid #1A1A1A;
                            margin-left: setnum(12);
                        }
                        .tfirst > img, .tnext > img{
                            width: setnum(7.41);
                            height: setnum(12);
                        }

                        .tNextFirstActionWith{
                            background: #FFFFFF;
                        }
                        .tNextFirstAction{
                            opacity: 1 !important;
                            background: #1A1A1A;
                            border: setnum(1) solid #1A1A1A;
                        }
                    }
                }
            }

            .tabb{
                @include boxflex();
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-bottom: setnum(80);
                .rcontent{
                    margin-top: setnum(150);
                    margin-right: setnum(161);
                    width: setnum(305);
                    .rdesc{
                        width: setnum(305);
                        margin-bottom: setnum(40);
                        p{
                            width: setnum(305);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            //text-align: justify;
                            color: #1A1A1A;
                        }
                    }
                    .rbtn{
                        @include boxflex();
                        justify-content: flex-start;

                        .tfirst, .tnext{
                            @include boxflex();
                            justify-content: center;
                            width: setnum(58);
                            height: setnum(58);
                            cursor: pointer;
                            transition: all 0.5s;
                        }
                        .tfirst{
                            background: #FFFFFF;
                            opacity: 0.3;
                            border: setnum(1) solid #1A1A1A;
                            margin-right: setnum(12);
                        }
                        .tnext{
                            background: #FFFFFF;
                            border: setnum(1) solid #1A1A1A;
                            margin-left: setnum(12);
                        }
                        .tfirst > img, .tnext > img{
                            width: setnum(7.41);
                            height: setnum(12);
                        }
                        .tNextFirstActionWith{
                            background: #FFFFFF;
                        }
                        .tNextFirstAction{
                            opacity: 1 !important;
                            background: #1A1A1A;
                            border: setnum(1) solid #1A1A1A;
                        }
                    }
                }
                .lcontent{
                    width: setnum(212+682-80);
                    height: setnum(300 + 370 - 80);
                    position: relative;
                    .lcarditem:first-child{
                        background: chartreuse;
                        width: setnum(212);
                        height: setnum(212);
                        position: absolute;
                        top: setnum(188-80);
                        left: 0;
                        z-index: 2;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(212*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(212);
                                height: setnum(212);
                            }
                        }
                    }
                    .lcarditem:nth-child(2){
                        background: chocolate;
                        width: setnum(452);
                        height: setnum(300);
                        position: absolute;
                        left: setnum(57);
                        bottom: 0;
                        z-index: 1;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(452*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(452);
                                height: setnum(300);
                            }
                        }
                    }
                    .lcarditem:last-child{
                        background: pink;
                        width: setnum(358);
                        height: setnum(455);
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 2;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(358*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(358);
                                height: setnum(455);
                            }
                        }
                    }

                }
            }
        }
        .cardc{
            background: #F7F5F4;
            padding: setnum(56) setnum(219) setnum(120) setnum(219);
            .contentc{
                margin-bottom: setnum(72);
                .cinfor{
                    .textanimate{
                        width: setnum(792.64);
                        height: setnum(134.01);
                        margin-bottom: setnum(50);
                    }
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }

                    }
                }
            }
            .tabs{
                .tag{
                    .tul{
                        @include boxflex();
                        margin-bottom: setnum(58);
                        .tli{
                            //width: setnum(160);
                            width: setnum(160);
                            box-sizing: border-box;
                            @include boxflex();
                            flex-direction: column;
                            justify-content: center;
                            padding: setnum(24) 0 setnum(31) 0;
                            background: transparent;
                            position: relative;
                            cursor: pointer;
                            //transition: all 0.5s;
                            .icon{
                                width: setnum(64);
                                height: setnum(64);
                                margin-bottom: setnum(16);
                                img{
                                    width: setnum(64);
                                    height: setnum(64);
                                }
                            }
                            p{
                                height: setnum(28);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(20);
                                line-height: setnum(28);
                                text-align: center;
                                color: #1A1A1A;
                                white-space:nowrap;
                            }
                            .triangle{
                                position: absolute;
                                left: 41%;
                                bottom: setnum(-5);
                                display: none;
                                width: 0;
                                height: 0;
                                border-width: setnum(9);
                                border-style: solid;
                                border-color: transparent transparent transparent transparent;
                                transform: rotate(-135deg);
                                transition: all 0.7s;
                                opacity: 0;
                            }
                            &:hover{
                                background: #FFF;
                                .triangle{
                                    display: inline-block;
                                    opacity: 1;
                                    border-color: #FFF #FFF #FFF #FFF;
                                }
                            }

                        }
                        .tliactive {
                            background: #FFF;
                            .triangle{
                                display: inline-block;
                                opacity: 1;
                                border-color: #FFF #FFF #FFF #FFF;
                            }
                        }
                    }
                }
                .table{
                    table{
                        width: 100%;
                        border-collapse: collapse;
                        background: #FFF;
                        thead{
                            tr{

                                th{
                                    text-align: left;
                                    padding-left: setnum(60);
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(14);
                                    color: #1A1A1A;
                                    opacity: 0.3;
                                    border-bottom: setnum(1) solid #f4f4f4;
                                    height: setnum(84);
                                }
                            }
                        }
                        tbody{
                            tr{
                                td{
                                    //width: setnum(400);
                                    width: 33.333%;
                                    text-align: left;
                                    padding-left: setnum(60);
                                    height: setnum(101);
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: setnum(16);
                                    color: #1A1A1A;
                                    opacity: 0.8;
                                    cursor: pointer;
                                }
                                td:last-child{
                                    .jobdetails{
                                        @include boxflex();
                                        .arrowIcon{
                                            visibility: hidden;
                                            margin-right: setnum(36);
                                            width: setnum(24);
                                        }
                                    }
                                }
                            }
                            .tractive:hover{
                                td{
                                    font-weight: 500;
                                    opacity: 1;

                                }
                                td:last-child{
                                    .jobdetails{
                                        @include boxflex();
                                        .arrowIcon{
                                            display: inline-block;
                                            visibility: visible;
                                            margin-right: setnum(36);
                                            width: setnum(24);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.normalH5{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            margin-bottom: setnum(56);
            .content{
                .rowa{}
                .rowb{
                    .textanimate{
                        width: setnum(280);
                        height: setnum(68);
                        margin-left: setnum(140);
                        margin-bottom: setnum(30);
                    }
                    .ccb{
                        padding: 0 setnum(24);
                        @include boxflex();
                        justify-content: flex-end;
                        align-items: flex-end;
                        margin-bottom: setnum(72);
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }

                    }
                }
            }

            .list{
                .cul{
                    display: grid;
                    grid-template-columns: 50% 50%;
                    //grid-column-gap: setnum(16);
                    .cli{
                        width: setnum(195);
                        margin: auto auto setnum(56) auto;
                        .libox{
                            @include boxflex();
                            flex-direction: column;
                            justify-content: center;
                            .icon{
                                width: setnum(80);
                                height: setnum(80);
                                margin-bottom: setnum(30);
                                //margin: auto auto setnum(16) auto;
                                img{
                                    width: setnum(80);
                                    height: setnum(80);
                                }
                            }
                            .h5zh{
                                height: setnum(30);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(24);
                                line-height: setnum(30);
                                text-align: center;
                                color: #1A1A1A;
                                margin-bottom: setnum(19);
                            }
                            .h5en{
                                @include boxflex();
                                justify-content: center;
                                height: setnum(56);
                                font-family: Lato;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(20);
                                line-height: setnum(28);
                                text-align: center;
                                color: #1A1A1A;
                                margin-bottom: setnum(12);
                                h5{
                                    width: setnum(146);
                                }
                            }

                            .innerTexts{
                                p{
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(16);
                                    line-height: setnum(32);
                                    text-align: center;
                                    color: #1A1A1A;
                                    opacity: 0.6;
                                }
                            }
                        }
                    }
                }
            }
        }
        .cardb{
            .contentb{
                .binfor{
                    .textanimate{
                        width: setnum(678);
                        height: setnum(68);
                        padding-left: setnum(24);
                        margin-bottom: setnum(50)
                    }

                    .ccb{
                        padding-left: setnum(24);
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: setnum(32);
                        // transform: translate(1.9%, 0%);
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }

                    }
                }
            }
            .taba{
                @include boxflex();
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: setnum(56);
                .lcontent{
                    width: 100%;
                    height: setnum(130+120);
                    position: relative;
                    margin-bottom: setnum(24);
                    .lcarditem:first-child{
                        width: setnum(155);
                        height: setnum(197);
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(155*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(155);
                                height: setnum(197);
                            }
                        }
                    }
                    .lcarditem:nth-child(2){
                        width: setnum(196);
                        height: setnum(130);
                        position: absolute;
                        right: setnum(96);
                        bottom: 0;
                        z-index: 2;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(196*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(196);
                                height: setnum(130);
                            }
                        }
                    }
                    .lcarditem:last-child{
                        width: setnum(177);
                        height: setnum(100);
                        position: absolute;
                        top: setnum(38);
                        right: setnum(24);
                        z-index: 3;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(177*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(177);
                                height: setnum(100);
                            }
                        }
                    }

                }
                .rcontent{
                    width: 100%;
                    .rdesc{
                        padding: 0 setnum(24);
                        p{
                            width: setnum(305);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            color: #1A1A1A;
                        }
                    }
                    .rbtn{
                        @include boxflex();
                        justify-content: flex-start;
                        padding: 0 setnum(24);
                        margin-bottom: setnum(18);
                        .tfirst, .tnext{
                            @include boxflex();
                            justify-content: center;
                            width: setnum(40);
                            height: setnum(40);
                            cursor: pointer;
                        }
                        .tfirst{
                            background: #FFFFFF;
                            opacity: 0.3;
                            border: setnum(1) solid #1A1A1A;
                            margin-right: setnum(12);
                        }
                        .tnext{
                            background: #FFFFFF;
                            border: setnum(1) solid #1A1A1A;
                            margin-left: setnum(12);
                        }
                        .tfirst > img, .tnext > img{
                            width: setnum(7.41);
                            height: setnum(12);
                        }
                    }
                }
            }

            .tabb{
                @include boxflex();
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;
                margin-bottom: setnum(56);
                .rcontent{
                    //margin-top: setnum(150);
                    //margin-right: setnum(161);
                    //width: setnum(305);

                    .rdesc{
                        width: setnum(305);
                        margin-bottom: setnum(25);
                        p{
                            width: setnum(305);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            //text-align: justify;
                            color: #1A1A1A;
                        }
                    }
                    .rbtn{
                        @include boxflex();
                        justify-content: flex-start;

                        .tfirst, .tnext{
                            @include boxflex();
                            justify-content: center;
                            width: setnum(40);
                            height: setnum(40);
                            cursor: pointer;
                        }
                        .tfirst{
                            background: #FFFFFF;
                            opacity: 0.3;
                            border: setnum(1) solid #1A1A1A;
                            margin-right: setnum(12);
                        }
                        .tnext{
                            background: #FFFFFF;
                            border: setnum(1) solid #1A1A1A;
                            margin-left: setnum(12);
                        }
                        .tfirst > img, .tnext > img{
                            width: setnum(7.41);
                            height: setnum(12);
                        }
                    }
                }
                .lcontent{
                    width: 100%;
                    height: setnum(146 + 144);
                    margin-left: setnum(24);
                    margin-bottom: setnum(24);
                    position: relative;
                    .lcarditem:first-child{
                        background: chartreuse;
                        width: setnum(104);
                        height: setnum(104);
                        position: absolute;
                        top: setnum(188-80);
                        left: 0;
                        z-index: 2;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(104*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(104);
                                height: setnum(104);
                            }
                        }
                    }
                    .lcarditem:nth-child(2){
                        background: chocolate;
                        width: setnum(221);
                        height: setnum(146);
                        position: absolute;
                        left: setnum(57);
                        bottom: 0;
                        z-index: 1;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(221*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(221);
                                height: setnum(146);
                            }
                        }
                    }
                    .lcarditem:last-child{
                        background: pink;
                        width: setnum(175);
                        height: setnum(222);
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 2;
                        overflow: hidden;
                        .lcarditemBox{
                            @include boxflex();
                            justify-content: flex-start;
                            width: setnum(175*2);
                            transition: all 0.5s;
                            .lcarditemChiren{
                                width: setnum(175);
                                height: setnum(222);
                            }
                        }
                    }

                }
            }
        }
        .cardc{
            background: #F7F5F4;
            padding-bottom: setnum(72);
            .contentc{
                margin-bottom: setnum(32);
                .cinfor{
                    .textanimate{
                        width: setnum(252);
                        height: setnum(68);
                        margin: 0 setnum(24);
                        padding: setnum(56) 0 setnum(16) 0;
                    }
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        margin: 0 setnum(24);
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }

                    }
                }
            }
            .tabs{
                .tag{
                    overflow-y: scroll;
                    .tul{
                        @include boxflex();
                        margin-bottom: setnum(18);
                        margin-left: setnum(24);
                        .tli{
                            margin-right: 19px;
                            width: setnum(128);
                            box-sizing: border-box;
                            @include boxflex();
                            flex-direction: column;
                            justify-content: center;
                            padding: setnum(11) 0;
                            background: transparent;
                            position: relative;
                            cursor: pointer;
                            .icon{
                                width: setnum(64);
                                height: setnum(64);
                                margin-bottom: setnum(16);
                                img{
                                    width: setnum(64);
                                    height: setnum(64);
                                }
                            }
                            .pzh{
                                height: setnum(28);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 600;
                                font-size: setnum(13);
                                //line-height: setnum(20);
                                text-align: center;
                                color: #1A1A1A;
                            }
                            .pen{
                                height: setnum(28*2);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(13);
                                line-height: setnum(20);
                                text-align: center;
                                color: #1A1A1A;
                            }
                            .triangle{
                                position: absolute;
                                left: 41%;
                                bottom: setnum(-5);
                                display: none;
                                width: 0;
                                height: 0;
                                border-width: setnum(9);
                                border-style: solid;
                                border-color: transparent transparent transparent transparent;
                                transform: rotate(-135deg);
                                transition: all 0.7s;
                                opacity: 0;
                            }
                            &:hover{
                                background: #FFF;
                                .triangle{
                                    display: inline-block;
                                    opacity: 1;
                                    border-color: #FFF #FFF #FFF #FFF;
                                }
                            }

                        }
                        .tliactive {
                            background: #FFF;
                            .triangle{
                                display: inline-block;
                                opacity: 1;
                                border-color: #FFF #FFF #FFF #FFF;
                            }
                        }
                    }
                }
                /* 隐藏滚动条 */
                .tag::-webkit-scrollbar {
                    display: none;
                }
                .table{
                    margin: 0 setnum(24);
                    table{
                        width: 100%;
                        border-collapse: collapse;
                        background: #FFF;
                        thead{
                            tr{

                                th{
                                    text-align: left;
                                    padding-left: setnum(10);
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(13);
                                    color: #1A1A1A;
                                    opacity: 0.3;
                                    border-bottom: setnum(1) solid #f4f4f4;
                                    height: setnum(44);
                                }
                            }
                        }
                        tbody{
                            tr{
                                td{
                                    text-align: left;
                                    padding: 0 setnum(10);
                                    height: setnum(60);
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: setnum(13);
                                    color: #1A1A1A;
                                    opacity: 0.8;
                                    cursor: pointer;
                                }
                                td:first-child{
                                    width: 48%;
                                    //background:red;
                                }
                                td:nth-child(2){
                                    width: 26%;
                                    //background:pink;
                                }
                                td:last-child{
                                    width: 36%;
                                    //background: deeppink;
                                    text-align: center;;
                                    .jobdetails{
                                        @include boxflex();
                                        span{
                                            text-align: center;
                                            width: 100%;
                                            color: #B0A27C;
                                        }
                                        .arrowIcon{
                                            visibility: hidden;
                                            margin-right: setnum(36);
                                            width: setnum(24);
                                        }
                                    }
                                }
                            }
                            .tractive:hover{
                                td{
                                    font-weight: 500;
                                    opacity: 1;

                                }
                                td:last-child{
                                    .jobdetails{
                                        @include boxflex();
                                        .arrowIcon{
                                            display: inline-block;
                                            visibility: visible;
                                            margin-right: setnum(36);
                                            width: setnum(24);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}