@import '../../global.module';

.normal{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            .content{
                padding: setnum(204) 0 0 setnum(220);
                .rowa{
                    @include boxflex();
                    margin-bottom: setnum(128);
                   .rleft{
                        width: setnum(1002);
                        height: setnum(288);
                        h1{
                            width: setnum(1002);
                            height: setnum(288);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: 800;
                            font-size: setnum(240);
                            line-height: setnum(288);
                            color: #E7E3D8;
                            margin-bottom: setnum(8);
                       }
                       .text{
                            stroke-linecap: butt;
                       }
                       
                   }
                   .rright{
                        width: setnum(788);
                        height: setnum(550);
                        background: url(../../assets/news/ibn.svg) no-repeat  100% 100%/100%;
                   }
                } 
                .rowb{
                    padding-left: setnum(657);
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: setnum(72);
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }
        
                    }
                }
            }
        }
        .cardb{
            background: #FFF;
            padding: setnum(56) 0 setnum(120) 0;
        }
    }
}

.normalh5{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            margin-bottom: setnum(32);
            .content{
                .rowa{
                    padding: setnum(172) setnum(24) setnum(0) setnum(24);
                    @include boxflex();
                   .rleft{
                       position: relative;
                        h1{
                            height: setnum(68);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: 800;
                            font-size: setnum(56);
                            line-height: setnum(68);
                            color: #E7E3D8;
                            margin-bottom: setnum(8);
                       }
                       .text{
                            stroke-linecap: butt;
                       }

                   }
                   .rright{
                        width: setnum(236.4);
                        height: setnum(165);
                        background: url(../../assets/news/ibn.svg) no-repeat  100% 100%/100%;
                        position: absolute;
                        right: 0;
                        top: setnum(100);
                   }
                }
            }
        }
        .cardb{
            background: #FFF;
            padding: setnum(0) 0 setnum(72) 0;
        }
    }
}