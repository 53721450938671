@import '../../global.module';

@mixin localesAnim{
    .scrollAnimation{
        height: setnum(24);
        min-height: 24px;
        overflow: hidden;
        .scrollText{
            transition: all 0.5s;
            transform: translate(0%, 0%);
            span{
                display: block;
            }
            span:first-child{
                margin-bottom: setnum(10);
            }
        }
        .scrollText:hover{
            transform: translate(0%, -58%);
        }
    }
}

.normal{
    @include boxflex();
    justify-content: center;
    :global{
        /*span{
            font-weight: 500;
            font-size: setnum(14);
            margin-right: setnum(10);
            cursor:pointer;
            border-bottom: setnum(2) solid $bgTran;
            padding-bottom: setnum(8);
            color: #1A1A1A;
        }
        span.line{
            display: inline-block;
            width: setnum(1);
            height: setnum(4);
            background: #1A1A1A;
            opacity: 0.6;
            margin-top: setnum(-8);
        }
        span:last-child{
            margin-right: setnum(0);
        }*/
        .langActive{
            color: #1A1A1A;
            opacity: 0.6;
        }

        .zhCn, .enUs{
            display: inline-block;

            span{
                font-weight: 500;
                font-size: setnum(14);
                cursor:pointer;
                border-bottom: setnum(2) solid $bgTran;
                padding-bottom: setnum(8);
                color: #1A1A1A;
            }
        }
        .lines{
            display: inline-block;
            width: setnum(1);
            height: setnum(13);
            background: #1A1A1A;
            opacity: 0.6;
            margin-top: setnum(-2.1)
        }
        .zhCn{
            margin-right: setnum(10);
            @include localesAnim();
        }
        .enUs{
            margin-left: setnum(10);
            @include localesAnim();
            span{
                //padding-top: setnum(4);
                padding-top: setnum(2.2);
            }
        }


        
    }
}


.normalH5{
    @include boxflex();
    justify-content: center;
    :global{
        span{
            font-weight: 500;
            font-size: setnum(14);
            margin-right: setnum(10);
            cursor:pointer;
            border-bottom: setnum(2) solid $bgTran;
            padding-bottom: setnum(8);
            color: #1A1A1A;
        }
        span.line{
            display: inline-block;
            width: setnum(1);
            height: setnum(4);
            background: #1A1A1A;
            opacity: 0.6;
            margin-top: setnum(-8);
        }
        span:last-child{
            margin-right: setnum(0);
        }
        .langActive{
            color: #1A1A1A;
            opacity: 0.6;
        }



    }
}
