@import '../../global.module';

.normalMax{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            margin-bottom: setnum(35);
            .content{
                padding: setnum(204) 0 0 setnum(220);
                .rowa{
                    @include boxflex();
                   .rleft{
                        width: setnum(1002);
                        height: setnum(288);
                        h1{
                            width: setnum(1002);
                            height: setnum(288);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: 800;
                            font-size: setnum(240);
                            line-height: setnum(288);
                            color: #E7E3D8;
                            margin-bottom: setnum(8);
                       }
                       .text{
                            stroke-linecap: butt;
                       }
                       
                   }
                   .rright{
                        width: setnum(788);
                        height: setnum(550);
                        background: url(../../assets/game/games.svg) no-repeat  100% 100%/100%;
                   }
                } 
                .rowb{
                    padding-left: setnum(657);
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }
        }
        
        .cardb{
            background: #FFF;
        }
    }
}

.normal{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            margin-bottom: setnum(35);
            .content{
                padding: setnum(204) 0 0 setnum(220);
                .rowa{
                    @include boxflex();
                   .rleft{
                        width: setnum(1002);
                        height: setnum(288);
                        h1{
                            width: setnum(1002);
                            height: setnum(288);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: 800;
                            font-size: setnum(240);
                            line-height: setnum(288);
                            color: #E7E3D8;
                            margin-bottom: setnum(8);
                       }
                       .text{
                            stroke-linecap: butt;
                       }

                   }
                   .rright{
                        width: setnum(788);
                        height: setnum(550);
                        background: url(../../assets/game/games.svg) no-repeat  100% 100%/100%;
                   }
                }
                .rowb{
                    padding-left: setnum(657);
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }
        }

        .cardb{
            background: #FFF;
        }
    }
}

.normalh5{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            margin-bottom: setnum(32);
            .content{
                .rowa{
                  padding: setnum(172) setnum(24) setnum(0) setnum(24);
                  @include boxflex();
                  .rleft{
                    position: relative;
                    h1{
                      height: setnum(68);
                      font-family: Lato;
                      font-style: normal;
                      font-weight: 800;
                      font-size: setnum(56);
                      line-height: setnum(68);
                      color: #E7E3D8;
                      margin-bottom: setnum(8);
                    }
                    .text{
                      stroke-linecap: butt;
                    }

                  }
                  .rright{
                    width: setnum(236.4);
                    height: setnum(165);
                    background: url(../../assets/game/games.svg) no-repeat  100% 100%/100%;
                    position: absolute;
                    right: 0;
                    top: setnum(100);
                  }
                }
                .rowb{
                    padding-left: setnum(657);
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }
        }

        .cardb{
            background: #FFF;
        }
    }
}