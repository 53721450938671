@import '../../../../global.module';

// 引用动画 start
.svgPath {
    @include SvgPaths(#E7E3D8, #CCCCCC);
}
.svgPathAnimation {
    @include SvgPathAnimations(166);
}
// 引用动画 end

.normal{
    @include boxflex();
    //flex-direction: column;
    justify-content: center;
    :global{

    }
}

.normalM{
    @include boxflex();
    //flex-direction: column;
    justify-content: center;
    width: 100%;
    :global{

    }
}