@import '../../../global.module';

// 引用动画 start
.svgPath {
    //@include SvgPaths(rgba(204, 204, 204, 1), rgba(230, 227, 217, 1)); // 247, 245, 244, 1
    @include SvgPaths(rgba(247, 245, 244, 1), rgba(204, 204, 204, 1)); // 247, 245, 244, 1
}
.svgPathAnimation {
    @include SvgPathAnimations(10);
}
// 引用动画 end

.normal{
    @include boxflex();
    //flex-direction: column;
    justify-content: center;
    :global{

    }
}

.normalM{
    @include boxflex();
    //flex-direction: column;
    justify-content: center;
    width: 100%;
    :global{

    }
}