@import '../../global.module';

.normalMax{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            margin-bottom: setnum(120);
        }
        .cardb{
            height: setnum(398+410-252);
            width: setnum(855+730-80);
            //border: 1px solid red;
            margin: 0 auto setnum(72) auto;
            position: relative;
            .left{
                width: setnum(855);
                //height: setnum(398);
                background: #F7F5F4;
                position: absolute;
                top: 0;
                left: setnum(0);
                z-index: 2;
                .linfo{
                    padding: setnum(120) setnum(60);
                    @include boxflex();
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    p:first-child{
                        height: setnum(30);
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        color: #1A1A1A;
                        margin-bottom: setnum(32);
                    }
                    p:last-child{
                        width: setnum(615);
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: setnum(16);
                        line-height: setnum(32);
                        color: #1A1A1A;
                        opacity: 0.8;
                    }
                }
            }
            .right{
                position: absolute;
                right: setnum(0);
                bottom: 0;
                width: setnum(730);
                height: setnum(410);
                z-index: 1;
            }
        }
        .cardc{
            position: relative;
            height: setnum(700+713-72);
            //background: red;
            margin-bottom: setnum(120);
            //width: 100%;
            .row:first-child{
                @include boxflex();
                justify-content: center;
                align-items: flex-start;
                background: #FFF;
                //width: setnum(490+56+539);
                margin: 0 auto 0 auto;
                .left{
                    width: setnum(590);
                    height: setnum(737);
                    .limg{
                        width: setnum(590);
                        height: setnum(737);
                    }
                }
                .right{
                    padding-top: setnum(202);
                    margin-left: setnum(96);
                    margin-right: setnum(76);
                    .title{
                        height: setnum(30);
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        color: #1A1A1A;
                        margin-bottom: setnum(16);
                    }
                    .rectangle{
                        width: setnum(24);
                        height: setnum(3);
                        background: #B0A27C;
                        margin-bottom: setnum(32);
                    }
                    .text{
                        width: setnum(610);
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: setnum(16);
                        line-height: setnum(32);
                        color: #1A1A1A;
                        opacity: 0.8;
                    }
                }
            }
            .row:last-child{
                @include boxflex();
                justify-content: center;
                align-items: flex-end;
                background: transparent;
                //width: setnum(610+700+96);
                height: setnum(428);
                overflow: hidden;
                bottom: 0;
                position: absolute;
                right: setnum(154);
                .left{
                    //padding-left: setnum(85);
                    padding-bottom: setnum(72);
                    width: setnum(610);
                    .title{
                        height: setnum(30);
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        color: #1A1A1A;
                        margin-bottom: setnum(16);
                    }
                    .rectangle{
                        width: setnum(24);
                        height: setnum(3);
                        background: #B0A27C;
                        margin-bottom: setnum(32);
                    }
                    .text{
                        //width: setnum(610);
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: setnum(16);
                        line-height: setnum(32);
                        color: #1A1A1A;
                        opacity: 0.8;
                    }
                }
                .right{
                    width: setnum(700);
                    height: setnum(500);
                    margin-left: setnum(66);
                    .rimg{
                        width: setnum(700);
                        height: setnum(500);
                    }
                }
            }
        }
        .cardd{
            background: url(../../assets/about/card4/bg101.svg) no-repeat 100% 100%/cover;
            padding: setnum(56) 0 setnum(120) 0;
            .gamesBorderTextBox{
                height: setnum(176.76);
                width: 100%;
                opacity: 1;
                margin-bottom: setnum(50);
                position: relative;
                .gamesBorderTextBoxPosition{
                    position: absolute;
                    right: setnum(-88);

                }
            }
            .enterpriseculture{
                padding: 0 setnum(80) 0 0;
                @include boxflex();
                flex-direction: column;
                align-items: flex-end;
                margin-bottom: setnum(72);
                .title{
                    font-weight: 500;
                    font-size: setnum(20);
                    height: setnum(28);
                    line-height: setnum(28);
                    text-align: right;
                    color: #B0A27C;
                    margin-bottom: setnum(16);
                }
                .subtitle{
                    font-weight: bold;
                    font-size: setnum(56);
                    width: setnum(336);
                    height: setnum(160);
                    line-height: setnum(80);
                    text-align: right;
                    color: #FFFFFF;
                    margin-bottom: setnum(32);
                }
                .rectangle{
                    width: setnum(40);
                    height: setnum(6);
                    background: #FFFFFF;
                }

            }
            .culturelist{
                @include boxflex();
                justify-content: center;
                align-items: flex-start;
                .item{
                    margin:0 setnum(155/2);
                    .card{
                        .crcectangle{
                            width: setnum(204);
                            background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
                            border-radius: setnum(4);
                            border: setnum(1) solid #535455;
                            padding: setnum(40) 0 setnum(16) 0;
                            margin-bottom: setnum(32);
                            .cicon{
                                height: setnum(100);
                                @include boxflex();
                                justify-content: center;
                                align-items: center;
                                margin-bottom: setnum(44);
                                img{
                                    width: setnum(100);
                                    height: setnum(100);
                                }
                            }
                            .info{
                                margin-left: setnum(16);
                                p:first-child{
                                    height: setnum(30);
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: setnum(24);
                                    line-height: setnum(30);
                                    color: #FFFFFF;
                                    margin-bottom: setnum(8);
                                }
                                p:last-child{
                                    height: setnum(20);
                                    font-family: Lato;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(16);
                                    line-height: setnum(20);
                                    color: #FFFFFF;
                                    opacity: 0.8;
                                }
                            }
                        }
                        .cdesc{
                            width: setnum(210);
                            p:first-child{
                                margin-bottom: setnum(8);
                                i{
                                    color: #FFFFFF;
                                    opacity: 0.3;
                                }
                                span{
                                    height: setnum(20);
                                    font-family: Lato;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(16);
                                    line-height: setnum(20);
                                    color: #FFFFFF;
                                    opacity: 0.3;
                                    margin-left: setnum(8);
                                }
                            }
                            p:last-child{
                                //height: setnum(32);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: normal;
                                font-size: setnum(16);
                                line-height: setnum(32);
                                color: #FFFFFF;
                                word-break:break-all;
                            }
                        }
                    }
                }
            }
        }
        .carde{
            height: setnum(2251);
            background: #F7F5F4;
            padding: setnum(56) 0 setnum(0) 0;
            .gamesBorderTextBox {
                height: setnum(176.76);
                width: 100%;
                opacity: 1;
                margin-bottom: setnum(72);
                position: relative;
                .gamesBorderTextBoxPosition {
                    position: absolute;
                    left: setnum(-98);

                }
            }
            .cdh{
                padding: 0 0 0 setnum(340);
                @include boxflex();
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: setnum(72);
                .title{
                    font-weight: 500;
                    font-size: setnum(20);
                    height: setnum(28);
                    line-height: setnum(28);
                    text-align: left;
                    color: #B0A27C;
                    margin-bottom: setnum(16);
                }
                .subtitle{
                    font-weight: bold;
                    font-size: setnum(56);
                    text-align: left;
                    color: #1A1A1A;
                    margin-bottom: setnum(32);
                    span{
                        display: block;
                        height: setnum(80);
                        line-height: setnum(80);
                    }
                }
                .rectangle{
                    width: setnum(40);
                    height: setnum(6);
                    background: #1A1A1A;
                }

            }
            .timeline{
                margin: 0 setnum(340);
                .item{
                    @include boxflex();
                    justify-content: flex-start;
                    align-items: center;
                    .year{
                        width: setnum(74);
                        height: setnum(30);
                        font-family: Lato;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        text-transform: uppercase;
                        color: #B0A27C;
                        span{
                            display: block;
                            width: setnum(74);
                            height: setnum(30);
                        }
                    }
                    .verticallinefirst{
                        width: setnum(6);
                        height: setnum(250);

                        margin-left: setnum(16);
                        .line{
                            width: setnum(6);
                            height: setnum(250);
                            @include boxflex();
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            img{
                                width: setnum(6);
                                height: setnum(250);
                            }
                        }

                    }
                    .verticallinelast{
                        width: setnum(6);
                        height: setnum(250);

                        margin-left: setnum(16);
                        .line{
                            width: setnum(6);
                            height: setnum(250);
                            @include boxflex();
                            justify-content: center;
                            align-items: flex-start;
                            position: relative;
                            img{
                                width: setnum(6);
                                height: setnum(125);
                            }
                        }

                    }
                    .month{
                        margin-left: setnum(32);
                        width: setnum(60);
                        height: setnum(28);
                        span{
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(20);
                            line-height: setnum(28);
                            color: #1A1A1A;
                            display: block;
                            width: setnum(60);
                            height: setnum(28);
                        }
                    }
                    .descInfo{
                        @include boxflex();
                        margin-left: setnum(65);
                        width: 100%;
                        .desc{
                            //width:  calc(100% - 3.2rem);
                            width: setnum(450);
                            @include boxflex();
                            flex-direction: column;
                            justify-content: center;
                            text-align: left;
                            a{
                                display: inline-block;
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: normal;
                                font-size: setnum(16);
                                line-height: setnum(32);
                                color: #1A1A1A;
                                width: 100%;
                                @include textEllipsis(7);
                            }
                        }
                        .imgbox{
                            box-sizing: border-box;
                            width: setnum(320);
                            height: setnum(180);
                            //margin-left: setnum(80);
                            img{
                                width: setnum(320);
                                height: setnum(180);
                            }
                        }
                    }

                }

            }
        }
    }
}

.normal{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            margin-bottom: setnum(120);
        }

        .cardb{
            height: setnum(398+80);
            width: setnum(738+630-80);
            //border: 1px solid red;
            margin: 0 auto setnum(72) auto;
            position: relative;
            .left{
                width: setnum(738);
                //height: setnum(398);
                background: #F7F5F4;
                position: absolute;
                top: 0;
                left: setnum(0);
                z-index: 2;
                .linfo{
                    padding: setnum(120) setnum(60);
                    @include boxflex();
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    p:first-child{
                        height: setnum(30);
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        color: #1A1A1A;
                        margin-bottom: setnum(32);
                    }
                    p:last-child{
                        width: setnum(615);
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: setnum(16);
                        line-height: setnum(32);
                        color: #1A1A1A;
                        opacity: 0.8;
                    }
                }
            }
            .right{
                position: absolute;
                right: setnum(0);
                bottom: 0;
                width: setnum(630);
                height: setnum(358);
                z-index: 1;
            }
        }

        .cardc{
            position: relative;
            height: setnum(966);
            margin-bottom: setnum(120);
            //width: 100%;
            .row:first-child{
                @include boxflex();
                justify-content: center;
                align-items: flex-start;
                background: #FFF;
                width: setnum(490+56+539);
                margin: 0 auto;
                .left{
                    width: setnum(490);
                    height: setnum(612);
                    .limg{
                        width: setnum(490);
                        height: setnum(612);
                    }
                }
                .right{
                    padding-top: setnum(150);
                    margin-left: setnum(96);
                    //width: setnum(539);
                    .title{
                        height: setnum(30);
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        color: #1A1A1A;
                        margin-bottom: setnum(16);
                    }
                    .rectangle{
                        width: setnum(24);
                        height: setnum(3);
                        background: #B0A27C;
                        margin-bottom: setnum(32);
                    }
                    .text{
                        width: setnum(539);
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: setnum(16);
                        line-height: setnum(32);
                        color: #1A1A1A;
                        opacity: 0.8;
                    }
                }
            }
            .row:last-child{
                @include boxflex();
                justify-content: center;
                align-items: flex-end;
                background: transparent;
                width: setnum(600+814-136);
                height: setnum(428);
                overflow: hidden;
                bottom: 0;
                position: absolute;
                //left: 50%;
                //margin-left: setnum(-(600+814-136)/2);    /* 宽度的一半 */
                right: setnum(26);
                .left{
                    padding-left: setnum(85);
                    padding-bottom: setnum(72);
                    width: setnum(539);
                    .title{
                        height: setnum(30);
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        color: #1A1A1A;
                        margin-bottom: setnum(16);
                    }
                    .rectangle{
                        width: setnum(24);
                        height: setnum(3);
                        background: #B0A27C;
                        margin-bottom: setnum(32);
                    }
                    .text{
                        width: setnum(539);
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: setnum(16);
                        line-height: setnum(32);
                        color: #1A1A1A;
                        opacity: 0.8;
                    }
                }
                .right{
                    width: setnum(600);
                    height: setnum(428);
                    margin-left: setnum(56);
                    .rimg{
                        width: setnum(600);
                        height: setnum(428);
                    }
                }
            }
        }

        .cardd{
            background: url(../../assets/about/card4/bg101.svg) no-repeat 100% 100%/cover;
            padding: setnum(56) 0 setnum(120) 0;
            .gamesBorderTextBox{
                height: setnum(134.01);
                width: 100%;
                opacity: 1;
                margin-bottom: setnum(50);
                position: relative;
                .gamesBorderTextBoxPosition{
                    position: absolute;
                    right: setnum(-68);

                }
            }
            .enterpriseculture{
                padding: 0 setnum(80) 0 0;
                @include boxflex();
                flex-direction: column;
                align-items: flex-end;
                margin-bottom: setnum(72);
                .title{
                    font-weight: 500;
                    font-size: setnum(20);
                    height: setnum(28);
                    line-height: setnum(28);
                    text-align: right;
                    color: #B0A27C;
                    margin-bottom: setnum(16);
                }
                .subtitle{
                    font-weight: bold;
                    font-size: setnum(56);
                    width: setnum(336);
                    height: setnum(160);
                    line-height: setnum(80);
                    text-align: right;
                    color: #FFFFFF;
                    margin-bottom: setnum(32);
                }
                .rectangle{
                    width: setnum(40);
                    height: setnum(6);
                    background: #FFFFFF;
                }

            }
            .culturelist{
                @include boxflex();
                justify-content: center;
                align-items: flex-start;
                .item{
                    margin:0 setnum(155/2);
                    .card{
                        .crcectangle{
                            width: setnum(204);
                            background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
                            border-radius: setnum(4);
                            border: setnum(1) solid #535455;
                            padding: setnum(40) 0 setnum(16) 0;
                            margin-bottom: setnum(32);
                            .cicon{
                                height: setnum(100);
                                @include boxflex();
                                justify-content: center;
                                align-items: center;
                                margin-bottom: setnum(44);
                                img{
                                    width: setnum(100);
                                    height: setnum(100);
                                }
                            }
                            .info{
                                margin-left: setnum(16);
                                p:first-child{
                                    height: setnum(30);
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: setnum(24);
                                    line-height: setnum(30);
                                    color: #FFFFFF;
                                    margin-bottom: setnum(8);
                                }
                                p:last-child{
                                    height: setnum(20);
                                    font-family: Lato;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(16);
                                    line-height: setnum(20);
                                    color: #FFFFFF;
                                    opacity: 0.8;
                                }
                            }
                        }
                        .cdesc{
                            width: setnum(210);
                            p:first-child{
                                margin-bottom: setnum(8);
                                i{
                                    color: #FFFFFF;
                                    opacity: 0.3;
                                }
                                span{
                                    height: setnum(20);
                                    font-family: Lato;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(16);
                                    line-height: setnum(20);
                                    color: #FFFFFF;
                                    opacity: 0.3;
                                    margin-left: setnum(8);
                                }
                            }
                            p:last-child{
                                //height: setnum(32);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: normal;
                                font-size: setnum(16);
                                line-height: setnum(32);
                                color: #FFFFFF;
                                word-break:break-all;
                            }
                        }
                    }
                }
            }
        }
        .carde{
            height: setnum(2251);
            background: #F7F5F4;
            padding: setnum(56) 0 setnum(0) 0;
            /*.gamesBorderTextBox{
                width: setnum(1965.1);
                height: setnum(134.01);
                margin-left: setnum(438);
                margin-bottom: setnum(72);
            }*/

            .gamesBorderTextBox {
                height: setnum(134.01);
                width: 100%;
                opacity: 1;
                margin-bottom: setnum(72);
                position: relative;
                .gamesBorderTextBoxPosition {
                    position: absolute;
                    left: setnum(-68);

                }
            }
            .cdh{
                padding: 0 0 0 setnum(219);
                @include boxflex();
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: setnum(72);
                .title{
                    font-weight: 500;
                    font-size: setnum(20);
                    height: setnum(28);
                    line-height: setnum(28);
                    text-align: left;
                    color: #B0A27C;
                    margin-bottom: setnum(16);
                }
                .subtitle{
                    font-weight: bold;
                    font-size: setnum(56);
                    text-align: left;
                    color: #1A1A1A;
                    margin-bottom: setnum(32);
                    span{
                        display: block;
                        height: setnum(80);
                        line-height: setnum(80);
                    }
                }
                .rectangle{
                    width: setnum(40);
                    height: setnum(6);
                    background: #1A1A1A;
                }

            }
            .timeline{
                margin: 0 setnum(219);
                .item{
                    @include boxflex();
                    justify-content: flex-start;
                    align-items: center;
                    .year{
                        width: setnum(74);
                        height: setnum(30);
                        font-family: Lato;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        text-transform: uppercase;
                        color: #B0A27C;
                        span{
                            display: block;
                            width: setnum(74);
                            height: setnum(30);
                        }
                    }
                    .verticallinefirst{
                        width: setnum(6);
                        height: setnum(250);
                        
                        margin-left: setnum(16);
                        .line{
                            width: setnum(6);
                            height: setnum(250);
                            @include boxflex();
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            img{
                                width: setnum(6);
                                height: setnum(250);
                            }
                        }
                        
                    }
                    .verticallinelast{
                        width: setnum(6);
                        height: setnum(250);
                        
                        margin-left: setnum(16);
                        .line{
                            width: setnum(6);
                            height: setnum(250);
                            @include boxflex();
                            justify-content: center;
                            align-items: flex-start;
                            position: relative;
                            img{
                                width: setnum(6);
                                height: setnum(125);
                            }
                        }
                        
                    }
                    .month{
                        margin-left: setnum(32);
                        width: setnum(60);
                        height: setnum(28);
                        span{
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(20);
                            line-height: setnum(28);
                            color: #1A1A1A;
                            display: block;
                            width: setnum(60);
                            height: setnum(28);
                        }
                    }
                    .descInfo{
                        @include boxflex();
                        margin-left: setnum(65);
                        width: 100%;
                        .desc{
                            //width:  calc(100% - 3.2rem);
                            width: setnum(382);
                            @include boxflex();
                            flex-direction: column;
                            justify-content: center;
                            text-align: left;
                            a{
                                display: inline-block;
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: normal;
                                font-size: setnum(16);
                                line-height: setnum(32);
                                color: #1A1A1A;
                                width: 100%;
                                @include textEllipsis(7);
                            }
                        }
                        .imgbox{
                            width: setnum(320);
                            height: setnum(180);
                            margin-left: setnum(80);
                            img{
                                width: setnum(320);
                                height: setnum(180);
                            }
                        }
                    }

                }
                
            }
        }
    }
}

.normalH5{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            margin-bottom: setnum(65);
        }

        .cardb{
            @include boxflex();
            justify-content: center;
            align-items: center;
            margin-bottom: setnum(72);
            //border: setnum(1) solid red;
            .bcontent{
                @include boxflex();
                justify-content: center;
                align-items: center;
                background: #FFF;
                height: setnum(408);
                position: relative;
                width: 100%;
                .left{
                    width: setnum(322);
                    box-sizing: border-box;
                    background: #F7F5F4;
                    position: absolute;
                    top: 0;
                    left: setnum(24);
                    z-index: 2;
                    .linfo{
                        padding: setnum(32) setnum(24);
                        @include boxflex();
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        p:first-child{
                            height: setnum(28);
                            font-weight: 500;
                            font-size: setnum(20);
                            line-height: setnum(28);
                            color: #1A1A1A;
                            margin-bottom: setnum(24);
                        }
                        p:last-child{
                            width: 100%;
                            //font-weight: 500;
                            //font-size: setnum(16);
                            //line-height: setnum(28);
                            //color: #1A1A1A;
                            //opacity: 0.8;
                            //text-align: justify;

                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(28);
                            color: #1A1A1A;
                            opacity: 0.8;
                        }
                    }
                }
                .right{
                    width: setnum(267);
                    height: setnum(150);
                    box-sizing: border-box;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 1;
                }
            }
        }

        .cardc{
            @include boxflex();
            justify-content: center;
            align-items: center;
            margin-bottom: setnum(72);
            .ccontent{
                .ldesc, .rdesc{
                    padding: 0 setnum(24);
                    margin-bottom: setnum(40);
                    .title{
                        height: setnum(28);
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(20);
                        line-height: setnum(28);
                        color: #1A1A1A;
                        margin-bottom: setnum(16);
                    }
                    .rectangle{
                        width: setnum(16);
                        height: setnum(3);
                        background: #B0A27C;
                        margin-bottom: setnum(32);
                    }
                    .text{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: setnum(16);
                        line-height: setnum(32);
                        color: #1A1A1A;
                        opacity: 0.8;
                    }
                }
                .rdesc{
                    margin-bottom: setnum(0);
                }
                .cimgBox{
                    width: 100%;
                    height: setnum(270);
                    position: relative;
                    margin-bottom: setnum(40);
                    .limg{
                        width: setnum(177);
                        height: setnum(211);
                        position: absolute;
                        top: 0;
                        left: 0;
                        img{
                            width: setnum(177);
                            height: setnum(211);
                        }
                    }
                    .rimg{
                        width: setnum(224);
                        height: setnum(160);
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        img{
                            width: setnum(224);
                            height: setnum(160);
                        }
                    }
                }
            }
        }

        .cardd{
            background: url(../../assets/about/card4/bg101.svg) no-repeat 100% 100%/cover;
            padding: setnum(56) 0 setnum(0) 0;
            .gamesBorderTextBox{
                width: setnum(550.44);
                height: setnum(68);
                opacity: 1;
                margin-left: setnum(-117);
                margin-bottom: setnum(16);
            }
            .enterpriseculture{
                padding: 0 setnum(24) 0 0;
                @include boxflex();
                flex-direction: column;
                align-items: flex-end;
                margin-bottom: setnum(32);
                .title{
                    font-weight: 500;
                    font-size: setnum(20);
                    height: setnum(28);
                    line-height: setnum(28);
                    text-align: right;
                    color: #B0A27C;
                    margin-bottom: setnum(16);
                }
                .subtitle{
                    font-weight: bold;
                    font-size: setnum(56);
                    width: setnum(336);
                    height: setnum(160);
                    line-height: setnum(80);
                    text-align: right;
                    color: #FFFFFF;
                    margin-bottom: setnum(32);
                }
                .rectangle{
                    width: setnum(40);
                    height: setnum(6);
                    background: #FFFFFF;
                }
            }
            .culturelist{
                display: grid;
                grid-template-columns: 49.64% 49.64%;
                grid-column-gap: setnum(12);
                margin: 0 setnum(24);

                .item{
                    margin:0 0 setnum(72) 0;
                    width: setnum(169);
                    .card{
                        .crcectangle{
                            background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
                            border-radius: setnum(4);
                            border: setnum(1) solid #535455;
                            padding: setnum(40) 0 setnum(16) 0;
                            margin-bottom: setnum(32);
                            .cicon{
                                height: setnum(100);
                                @include boxflex();
                                justify-content: center;
                                align-items: center;
                                margin-bottom: setnum(44);
                                img{
                                    width: setnum(100);
                                    height: setnum(100);
                                }
                            }
                            .info{
                                text-align: center;
                                p:first-child{
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: setnum(20);
                                    line-height: setnum(28);
                                    color: #FFFFFF;
                                    margin-bottom: setnum(8);
                                }
                                p:last-child{
                                    font-family: Lato;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(16);
                                    line-height: setnum(28);
                                    color: #FFFFFF;
                                    opacity: 0.8;
                                }
                            }
                        }
                        .cdesc{
                            //width: setnum(210);
                            p:first-child{
                                margin-bottom: setnum(8);
                                i{
                                    color: #FFFFFF;
                                    opacity: 0.3;
                                }
                                span{
                                    font-family: Lato;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: setnum(16);
                                    line-height: setnum(28);
                                    color: #FFFFFF;
                                    opacity: 0.3;
                                    margin-left: setnum(8);
                                }
                            }
                            p:last-child{
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: normal;
                                font-size: setnum(16);
                                line-height: setnum(28);
                                color: #FFFFFF;
                                word-break:break-all;
                            }
                        }
                    }
                }
            }
        }
        .carde{
            background: #F7F5F4;
            padding: setnum(56) 0 setnum(72) 0;
            .gamesBorderTextBox{
                width: setnum(620);
                height: setnum(68);
                margin-left: setnum(-20);
                margin-bottom: setnum(16);
            }
            .cdh{
                padding: 0 0 0 setnum(24);
                @include boxflex();
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: setnum(72);
            }
            .timeline{
                margin-left: setnum(24);
                .item{
                    @include boxflex();
                    justify-content: flex-start;
                    align-items: flex-start;
                    .year{
                        width: setnum(60);
                        height: setnum(28);
                        font-family: Lato;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(20);
                        line-height: setnum(28);
                        text-transform: uppercase;
                        color: #B0A27C;
                        padding-top: setnum(23);
                        span{
                            width: setnum(60);
                            height: setnum(28);
                            font-size: setnum(20);
                            line-height: setnum(28);
                            display: inline-block;
                        }
                    }
                    .verticallinefirst{
                        width: setnum(6);
                        height: setnum(298);
                        margin-left: setnum(8);
                        .line{
                            width: setnum(6);
                            height: setnum(298);
                            @include boxflex();
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            img{
                                width: setnum(6);
                                height: 100%;
                            }
                        }

                    }
                    .verticallinelast{
                        width: setnum(6);
                        height: setnum(326);
                        margin-left: setnum(8);
                        .line{
                            width: setnum(6);
                            height: setnum(298);
                            @include boxflex();
                            justify-content: center;
                            align-items: flex-start;
                            position: relative;
                            img{
                                width: setnum(6);
                                height: setnum(326);
                            }
                        }

                    }
                    .info{
                        padding-top: setnum(26);
                        margin-left: setnum(10);
                        width: setnum(285);
                        .month{
                            width: setnum(60);
                            height: setnum(24);
                            margin-bottom: setnum(16);
                            span{
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(17);
                                line-height: setnum(24);
                                color: #1A1A1A;
                            }
                        }
                        .desc{
                            width: setnum(285);
                            margin-bottom: setnum(16);
                            p{
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: normal;
                                font-size: setnum(16);
                                line-height: setnum(28);
                                color: #1A1A1A;
                                @include textEllipsis(2);

                            }
                        }
                        .imgbox{
                            width: setnum(285);
                            height: setnum(160);
                            img{
                                width: setnum(285);
                                height: setnum(160);
                            }
                        }
                    }

                }

            }
        }
    }
}