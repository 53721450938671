@import '../../global.module';

.normal{
    @include boxflex();
    flex-direction: column;
    justify-content: center;
    :global{
        .tab{
            @include boxflex();
            justify-content: center;
            margin-bottom: setnum(80);
            .item{
                margin: 0 setnum(16) 0 setnum(16);
                width: setnum(120);
                height: setnum(40);
                line-height: setnum(40);
                font-family: Noto Sans SC;
                font-style: normal;
                font-weight: 500;
                font-size: setnum(20);
                text-align: center;
                background: #FFFFFF;
                color: #1A1A1A;
                cursor: pointer;
                border: setnum(1) solid #bababa;
                transition: all 0.5s;
                &:hover{
                    background: #1A1A1A;
                    color: #FFFFFF;
                    border: 0.01rem solid #1A1A1A;
                }
            }
            .active{
                background: #1A1A1A;
                color: #FFFFFF;
                border: setnum(1) solid #1A1A1A;

            }
        }
        .list{
            @include boxflex();
            justify-content: center;
            width: setnum(1377);
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                background: #FFF;
                li{
                    width: setnum(395);
                    margin: 0 setnum(32) setnum(72) setnum(32);
                    background: #FFF;
                    cursor: pointer;
                    .pic{
                        width: setnum(395);
                        height: setnum(220);
                        margin-bottom: setnum(26);
                        img{
                            width: setnum(395);
                            height: setnum(220);
                        }
                    }
                    .content{
                        padding: 0 setnum(24) setnum(24) setnum(24);
                        .tag{
                            width: setnum(72);
                            height: setnum(26);
                            line-height: setnum(26);
                            font-family: Noto Sans SC;
                            font-size: setnum(14);
                            text-align: center;
                            background: #1A1A1A;
                            color: #FFF;
                            cursor: pointer;
                            margin-bottom: setnum(16);
                        }
                        .title{
                            font-family: Noto Sans SC;
                            height: setnum(56);
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(20);
                            line-height: setnum(28);
                            color: #1A1A1A;
                            margin-bottom: setnum(16);
                            @include textEllipsis(2);
                        }
                        .subtext{
                            margin-bottom: setnum(24);
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(14);
                            // line-height: setnum(22);
                            color: #000000;
                            opacity: 0.8;
                            @include textEllipsis(2);
                        }
                        .datatime{
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(12);
                            line-height: setnum(16);
                            color: #1A1A1A;
                            opacity: 0.6;
                        }
                    }
                    
                }
                li:hover{
                    box-shadow: 0px 10px 50px rgba(176, 162, 124, 0.2);
                }
            }
        }
        .btn{
            text-align: center;
            width: setnum(120);
            height: setnum(40);
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: 500;
            font-size: setnum(16);
            line-height: setnum(38);
            text-align: center;
            color: #1A1A1A;
            border: setnum(1)solid #1A1A1A;
            cursor: pointer;
            transition: all 0.5s;
            &:hover{
                background: #1A1A1A;
                color: #FFFFFF;
                border: 0.01rem solid #1A1A1A;
            }
        }
    }
}

.normalh5{
    @include boxflex();
    flex-direction: column;
    justify-content: center;
    :global{
        .tab{
            @include boxflex();
            justify-content: center;
            margin-bottom: setnum(32);
            .item{
                margin: 0 setnum(7.5) 0 setnum(7.5);
                width: setnum(112);
                height: setnum(40);
                line-height: setnum(40);
                font-family: Noto Sans SC;
                font-style: normal;
                font-weight: 500;
                font-size: setnum(17);
                text-align: center;
                background: #FFFFFF;
                color: #1A1A1A;
                cursor: pointer;
                border: setnum(1) solid #bababa;
            }
            .active{
                background: #1A1A1A;
                color: #FFFFFF;
                border: setnum(1) solid #1A1A1A;

            }
        }
        .list{
            @include boxflex();
            justify-content: center;
            margin-bottom: setnum(40);
            ul{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: #FFF;
                li{
                    width: setnum(366);
                    cursor: pointer;
                    margin-bottom: setnum(32);
                    background: #FFFFFF;
                    box-shadow: 0 setnum(9.28522) setnum(46.4261) rgba(176, 162, 124, 0.2);
                    .pic{
                        width: setnum(366);
                        height: setnum(203);
                        margin-bottom: setnum(18);
                        img{
                            width: setnum(366);
                            height: setnum(203);
                        }
                    }
                    .content{
                        padding: 0 setnum(16) setnum(24) setnum(16);
                        .tag{
                            width: setnum(68);
                            line-height: setnum(22);
                            font-family: Noto Sans SC;
                            font-size: setnum(13);
                            text-align: center;
                            background: #1A1A1A;
                            color: #FFF;
                            cursor: pointer;
                            margin-bottom: setnum(10);
                        }
                        .title{
                            font-family: Regular;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(16);
                            line-height: setnum(22);
                            color: #1A1A1A;
                            margin-bottom: setnum(16);
                            @include textEllipsis(2);
                        }
                        .subtext{
                            margin-bottom: setnum(24);
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(13);
                             line-height: setnum(18);
                            color: #000000;
                            opacity: 0.8;
                            @include textEllipsis(2);
                        }
                        .datatime{
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(11);
                            line-height: setnum(15);
                            color: #1A1A1A;
                            opacity: 0.6;
                        }
                    }

                }
                li:hover{
                    box-shadow: 0px 10px 50px rgba(176, 162, 124, 0.2);
                }
            }
        }
        .btn{
            width: setnum(96);
            height: setnum(36);
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: 500;
            font-size: setnum(16);
            line-height: setnum(34);
            text-align: center;
            color: #1A1A1A;
            border: setnum(1)solid #1A1A1A;
            cursor: pointer;
            transition: all 0.5s;
            &:hover{
                background: #1A1A1A;
                color: #FFFFFF;
                border: 0.01rem solid #1A1A1A;
            }
        }
    }
}