@import '../../global.module';

.footerMax{
    background: #FFF;
    padding: setnum(71) setnum(222) setnum(71) setnum(222);
    @include boxflex();
    justify-content: flex-start;
    :global{
        .content{
            @include boxflex();
            align-items: center;
            justify-content: center;
            .left{
                .logo{
                    width: setnum(143);
                    height: setnum(60);
                    cursor: pointer;
                    img{
                        width: setnum(143);
                        height: setnum(60);
                    }
                }

            }
            .center{
                margin-left: setnum(145);
                min-width: setnum(90);
                p{
                    //min-width: setnum(145);
                    display: block;
                    height: setnum(22);
                    line-height: setnum(22);
                    font-size: setnum(14);
                    opacity: 0.6;
                    margin-bottom: setnum(16);
                    cursor: pointer;
                    white-space:nowrap;
                }
                p:hover{
                    opacity: 1;
                    color: #1A1A1A;
                }

                p:first-child{
                    font-weight: 500;
                    font-size: setnum(18);
                    color: #1A1A1A;
                    opacity: 1;
                    margin-bottom: setnum(32);
                    cursor: inherit;
                }
                p:last-child{
                    margin-bottom: setnum(0);
                }
            }
            .right{
                margin-left: setnum(190);
                .fame{
                    padding-top: setnum(35);
                    width: setnum(675);
                    p{

                        font-size: setnum(12);
                        color: #1A1A1A;
                        opacity: 0.3;
                        margin-bottom: setnum(8);
                    }
                    p:last-child{
                        margin-bottom: setnum(24);
                    }

                }
                .community{
                    @include boxflex();
                    justify-content: flex-start;
                    .cicon{
                        width: setnum(36);
                        height: setnum(36);
                        @include boxflex();
                        justify-content: center;
                        cursor: pointer;
                        overflow: hidden;
                        img{
                            width: setnum(36);
                            height: setnum(36);
                        }
                    }
                    .cicon:first-child{
                        margin-right: setnum(8);
                    }

                }
            }
        }

    }
}

.footer{
    background: #FFF;
    padding: setnum(71) setnum(148) setnum(71) setnum(82);
    @include boxflex();
    justify-content: flex-start;
    :global{
        .content{
            @include boxflex();
            align-items: center;
            justify-content: center;
            .left{
                .logo{
                    width: setnum(143);
                    height: setnum(60);
                    cursor: pointer;
                    img{
                        width: setnum(143);
                        height: setnum(60);
                    }
                }

            }
            .center{
                margin-left: setnum(145);
                min-width: setnum(90);
                p{
                    //min-width: setnum(145);
                    display: block;
                    height: setnum(22);
                    line-height: setnum(22);
                    font-size: setnum(14);
                    opacity: 0.6;
                    margin-bottom: setnum(16);
                    cursor: pointer;
                    white-space:nowrap;
                }
                p:hover{
                    opacity: 1;
                    color: #1A1A1A;
                }

                p:first-child{
                    font-weight: 500;
                    font-size: setnum(18);
                    color: #1A1A1A;
                    opacity: 1;
                    margin-bottom: setnum(32);
                    cursor: inherit;
                }
                p:last-child{
                    margin-bottom: setnum(0);
                }
            }
            .right{
                margin-left: setnum(190);
                .fame{
                    padding-top: setnum(35);
                    width: setnum(675);
                    p{

                        font-size: setnum(12);
                        color: #1A1A1A;
                        opacity: 0.3;
                        margin-bottom: setnum(8);
                    }
                    p:last-child{
                        margin-bottom: setnum(24);
                    }
                    
                }
                .community{
                    @include boxflex();
                    justify-content: flex-start;
                    .cicon{
                        width: setnum(36);
                        height: setnum(36);
                        @include boxflex();
                        justify-content: center;
                        cursor: pointer;
                        overflow: hidden;
                        img{
                            width: setnum(36);
                            height: setnum(36);
                        }
                    }
                    .cicon:first-child{
                        margin-right: setnum(8);
                    }

                }
            }
        }
        
    }
}

.footerH5{
    background: #FFF;
    padding: setnum(56) setnum(24);
    :global{
        .row{
            @include boxflex();
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .logo{
                width: setnum(151.67);
                height: setnum(50);
                cursor: pointer;
                img{
                    width: setnum(151.67);
                    height: setnum(50);
                }
            }
            .community{
                @include boxflex();
                justify-content: center;
                .cicon{
                    width: setnum(32);
                    height: setnum(32);
                    @include boxflex();
                    justify-content: center;
                    cursor: pointer;
                    overflow: hidden;
                    img{
                        width: setnum(32);
                        height: setnum(32);
                    }
                }
                .cicon:first-child{
                    margin-right: setnum(8);
                }
            }
            .center{
                p{
                    min-width: setnum(190);
                    display: block;
                    height: setnum(28);
                    line-height: setnum(28);
                    font-size: setnum(16);
                    opacity: 0.6;
                    margin-bottom: setnum(16);
                    cursor: pointer;
                    text-align: center;
                }
                p:hover{
                    opacity: 1;
                    color: #1A1A1A;
                }
                p:last-child{
                    margin-bottom: setnum(0);
                }
            }

            .fame{
                p{
                    min-width: setnum(386);
                    font-size: setnum(11);
                    color: #1A1A1A;
                    opacity: 0.3;
                    margin-bottom: setnum(8);
                    text-align: center;
                    word-wrap: break-word;
                }
                p:last-child{
                    margin-bottom: 0;
                }

            }
        }
        .row:first-child{
            margin-bottom: setnum(24);
        }
        .row:nth-child(2){
            margin-bottom: setnum(24);
        }
        .row:nth-child(3){
            margin-bottom: setnum(24);
        }
    }
}

