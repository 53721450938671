@import '../../../global.module';

.normalMax{
    background: #FFF;
    overflow: hidden;
    :global{
        color: red;
        .content{
            padding: setnum(228) 0 0 0;
            .textanimate{
                width: setnum(1243);
                height: setnum($hMax);
                padding: 0 setnum(228);
                margin-bottom: setnum(50);
            }
            .jobdetails{
                padding: 0 setnum(470);
                .info{
                    margin-bottom: setnum(80);
                    p:first-child{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(20);
                        line-height: setnum(28);
                        color: #B0A27C;
                        margin-bottom: setnum(56);
                    }
                    h1{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(32);
                        line-height: setnum(40);
                        color: #1A1A1A;
                        margin-bottom: setnum(24);
                    }
                    p:last-child{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(16);
                        line-height: setnum(24);
                        color: #1A1A1A;
                        opacity: 0.6;
                    }
                }
                .row{
                    margin-bottom: setnum(56);
                    h2{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        color: #1A1A1A;
                        margin-bottom: setnum(16);
                    }
                    .desc{
                        p{
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            color: #1A1A1A;
                            opacity: 0.6;
                        }
                    }
                    h3{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(16);
                        line-height: setnum(24);
                        color: #1A1A1A;
                        cursor: pointer;
                        display: inline-block;
                    }
                }


            }
            .backtolist{
                text-align: center;
                @include boxflex();
                flex-direction: column;
                justify-content: center;
                padding: 0 setnum(400);
                margin: 0 0 setnum(88) 0;
                .line{
                    width: 100%;
                    height: 0;
                    opacity: 0.1;
                    border: setnum(1) solid #1A1A1A;
                    margin-bottom: setnum(56);
                }
                span{
                    display: block;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: bold;
                    font-size: setnum(16);
                    line-height: setnum(32);
                    text-align: center;
                    color: #1A1A1A;
                    cursor: pointer;
                }


                .scrollAnimation{
                    width: setnum(120);
                    height: setnum(32);
                    min-height: 32px;
                    overflow: hidden;
                    cursor: pointer;
                    .scrollText{
                        transition: all 0.5s;
                        transform: translate(0%, 0%);
                        span{
                            display: block;
                            font-family: Lato;
                            font-style: normal;
                            font-weight: bold;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            text-align: center;
                            color: #1A1A1A;
                            cursor: pointer;
                        }
                        span:first-child{
                            margin-bottom: setnum(10);
                        }
                    }
                    .scrollText:hover{
                        transform: translate(0%, -58%);
                    }
                }
            }
        }
    }
}

.normal{
    background: #FFF;
    overflow: hidden;
    :global{
        color: red;
        .content{
            padding: setnum(228) 0 ;
            .textanimate{
                width: setnum(1243);
                padding: 0 setnum(80);
                margin-bottom: setnum(50);
            }
            .jobdetails{
                padding: 0 setnum(297);
                .info{
                    margin-bottom: setnum(80);
                    p:first-child{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(20);
                        line-height: setnum(28);
                        color: #B0A27C;
                        margin-bottom: setnum(56);
                    }
                    h1{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(32);
                        line-height: setnum(40);
                        color: #1A1A1A;
                        margin-bottom: setnum(24);
                    }
                    p:last-child{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(16);
                        line-height: setnum(24);
                        color: #1A1A1A;
                        opacity: 0.6;
                    }
                }
                .row{
                    margin-bottom: setnum(56);
                    h2{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        color: #1A1A1A;
                        margin-bottom: setnum(16);
                    }
                    .desc{
                        p{
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            color: #1A1A1A;
                            opacity: 0.6;
                        }
                    }
                    h3{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(16);
                        line-height: setnum(24);
                        color: #1A1A1A;
                        cursor: pointer;
                        display: inline-block;
                    }
                }


            }
            .backtolist{
                text-align: center;
                @include boxflex();
                flex-direction: column;
                justify-content: center;
                padding: 0 setnum(259);
                margin: 0 0 setnum(88) 0;
                .line{
                    width: 100%;
                    height: 0;
                    opacity: 0.1;
                    border: setnum(1) solid #1A1A1A;
                    margin-bottom: setnum(56);
                }
                span{
                    display: block;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: bold;
                    font-size: setnum(16);
                    line-height: setnum(32);
                    text-align: center;
                    color: #1A1A1A;
                    cursor: pointer;
                }


                .scrollAnimation{
                    width: setnum(120);
                    height: setnum(32);
                    min-height: 32px;
                    overflow: hidden;
                    cursor: pointer;
                    .scrollText{
                        transition: all 0.5s;
                        transform: translate(0%, 0%);
                        span{
                            display: block;
                            font-family: Lato;
                            font-style: normal;
                            font-weight: bold;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            text-align: center;
                            color: #1A1A1A;
                            cursor: pointer;
                        }
                        span:first-child{
                            margin-bottom: setnum(10);
                        }
                    }
                    .scrollText:hover{
                        transform: translate(0%, -58%);
                    }
                }
            }
        }
    }
}

.normalH5{
    background: #FFF;
    overflow: hidden;
    :global{
        color: red;
        .content{
            //padding: setnum(228) 0 ;
            padding: setnum(228 /2) 0 0 0;
            .textanimate{
                width: setnum(236.4);
                height: setnum(40);
                padding: 0 setnum(24);
                margin-bottom: setnum(8);
            }
            .jobdetails{
                padding: 0 setnum(24);
                .info{
                    margin-bottom: setnum(80);
                    p:first-child{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(17);
                        line-height: setnum(24);
                        color: #B0A27C;
                        margin-bottom: setnum(56);
                    }
                    h1{
                        font-family: Lato;
                        font-style: normal;
                        font-weight: bold;
                        font-size: setnum(32);
                        line-height: setnum(40);
                        color: #1A1A1A;
                        margin-bottom: setnum(24);
                    }
                    p:last-child{
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(16);
                        line-height: setnum(24);
                        color: #1A1A1A;
                        opacity: 0.6;
                    }
                }
                .row{
                    margin-bottom: setnum(56);
                    h2{
                        font-family: Lato;
                        font-style: normal;
                        font-weight: 500;
                        font-size: setnum(20);
                        line-height: setnum(28);
                        color: #1A1A1A;
                        margin-bottom: setnum(16);
                    }
                    .desc{
                        p{
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            color: #1A1A1A;
                            opacity: 0.6;
                        }
                    }
                    h3{
                        font-family: Lato;
                        font-style: normal;
                        font-weight: normal;
                        font-size: setnum(16);
                        line-height: setnum(24);
                        color: #1A1A1A;
                        display: inline-block;
                        cursor: pointer;
                    }
                }


            }
            .backtolist{
                //width: setnum(885);
                //width: 100%;
                text-align: center;
                @include boxflex();
                flex-direction: column;
                justify-content: center;
                padding: 0 setnum(24);
                margin: 0 0 setnum(44) 0;
                .line{
                    width: 100%;
                    height: 0;
                    opacity: 0.1;
                    border: setnum(1) solid #1A1A1A;
                    margin-bottom: setnum(56);
                }
                .scrollAnimation{
                    width: setnum(120);
                    height: setnum(32);
                    min-height: 32px;
                    overflow: hidden;
                    cursor: pointer;
                    .scrollText{
                        transition: all 0.5s;
                        transform: translate(0%, 0%);
                        span{
                            display: block;
                            font-family: Lato;
                            font-style: normal;
                            font-weight: bold;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            text-align: center;
                            color: #1A1A1A;
                            cursor: pointer;
                        }
                        span:first-child{
                            margin-bottom: setnum(10);
                        }
                    }
                    .scrollText:hover{
                        transform: translate(0%, -58%);
                    }
                }
            }
        }
    }
}