@import '../../global.module';

.normal {
  :global {
    .anchorBL{
      display: none !important;
    }
    .BMap_Marker{
      width: setnum(119) !important;
      height: setnum(170) !important;
      left: setnum(283) !important;
      top: setnum(163) !important;
      background-size: setnum(119) setnum(170) !important;
      div {
        width: setnum(119) !important;
        height: setnum(170) !important;
        img{
          width: setnum(119) !important;
          height: setnum(170) !important;
        }
      }
    }

  }
}

.normalM {
  :global {
    .anchorBL{
      display: none !important;
    }
    .BMap_Marker{
      width: setnum(80) !important;
      height: setnum(112) !important;
      left: setnum(243) !important;
      top: setnum(163) !important;
      background-size: setnum(80) setnum(112) !important;
      div {
        width: setnum(80) !important;
        height: setnum(112) !important;
        img{
          width: setnum(80) !important;
          height: setnum(112) !important;
        }
      }
    }

  }
}