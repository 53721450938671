@import '../../global.module';


@keyframes lineAnimation {
    from {
        top: setnum(-200);
        //opacity: 1;
    }
    to {
        top: setnum(165);
        //opacity: 0;
    }
}

.lineFend{
    animation: lineAnimation 2s infinite linear ;
}


.normal{
    @include boxflex();
    flex-direction: column;
    align-items: center;
    justify-content: center;
    :global{
        .scroll{
            width: setnum(14);
            height: setnum(57);
            img{
                width: setnum(14);
                height: setnum(57);
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: setnum(12);
                line-height: setnum(14);
                letter-spacing: 0.2em;
                text-transform: uppercase;
                color: #1A1A1A;
                display: inline-block;
            }
        }
        .rectangle{
            margin-top: setnum(54);
            width: setnum(1);
            height: setnum(148);
            background: #b5b4b3;
            overflow: hidden;
            position: relative;
            .line{
                width: setnum(1);
                height: setnum(148);
                background: #1A1A1A;
                transition: all 1.3s ease-in;
            }
        }
    }
}