@import '../../../global.module';

// 引用动画 start
.svgPath {
    @include SvgPaths(transparent, rgba(204, 204, 204, 1));
}
.svgPathAnimation {
    @include SvgPathAnimations(166);
}
// 引用动画 end

.normal{
    @include boxflex();
    //flex-direction: column;
    justify-content: flex-start;
    :global{

    }
}

.normalM{
    @include boxflex();
    //flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    :global{

    }
}