@import '../../global.module';

.normalMax{
    @include boxflex();
    flex-direction: column;
    justify-content: center;
    :global{
        .list{
            @include boxflex();
            justify-content: center;
            ul{
                @include boxflex();
                flex-direction: column;
                //justify-content: space-between;
                justify-content: center;
                background: #FFF;
                //width: 100%;
                margin: setnum(0) setnum(80);
                li{
                    background: #FFF;
                    padding: setnum(96) setnum(0);
                    box-sizing: border-box;
                    .pic{
                        width: setnum(730);
                        height: setnum(410);
                    }
                    .content{
                        .title{
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(32);
                            line-height: setnum(40);
                            color: #1A1A1A;
                            margin-bottom: setnum(8);
                        }
                        .titleen{
                            //font-family: Lato;
                            font-style: normal;
                            font-weight: bold;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            color: #1A1A1A;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-family: Lato;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(14);
                            line-height: setnum(22);
                            color: #1A1A1A;
                            opacity: 0.3;
                            margin-bottom: setnum(39);
                            i{
                                display: inline-block;
                                width: setnum(1);
                                height: setnum(12);
                                opacity: 0.3;
                                background: #1A1A1A;
                                margin: 0 setnum(10);
                            }
                        }
                        .desc{
                            width: setnum(590);
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            //text-align: justify;
                            color: #1A1A1A;
                            margin-bottom: setnum(40);
                        }
                        .btn{
                            display: block;
                            outline: none;
                            cursor: pointer;
                            width: setnum(120);
                            height: setnum(40);
                            line-height: setnum(38);
                            background: #FFFFFF;
                            border: setnum(1) solid #1A1A1A;
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(16);
                            color: #1A1A1A;
                        }
                        .btn:hover{
                            transition: $tst;
                            background: #1A1A1A;
                            color: #FFF;
                        }
                    }

                }
            }
        }
    }
}

.normal{
    @include boxflex();
    flex-direction: column;
    justify-content: center;
    :global{
        .list{
            @include boxflex();
            justify-content: center;
            ul{
                @include boxflex();
                flex-direction: column;
                //justify-content: space-between;
                justify-content: center;
                background: #FFF;
                //width: 100%;
                margin: setnum(0) setnum(80);
                li{
                    background: #FFF;
                    padding: setnum(96) setnum(0);
                    box-sizing: border-box;
                    .pic{
                        width: setnum(630);
                        height: setnum(354);
                    }
                    .content{
                        .title{
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(32);
                            line-height: setnum(40);
                            color: #1A1A1A;
                            margin-bottom: setnum(8);
                        }
                        .titleen{
                            //font-family: Lato;
                            font-style: normal;
                            font-weight: bold;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            color: #1A1A1A;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-family: Lato;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(14);
                            line-height: setnum(22);
                            color: #1A1A1A;
                            opacity: 0.3;
                            margin-bottom: setnum(39);
                            i{
                                display: inline-block;
                                width: setnum(1);
                                height: setnum(12);
                                opacity: 0.3;
                                background: #1A1A1A;
                                margin: 0 setnum(10);
                            }
                        }
                        .desc{
                            width: setnum(540);
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            //text-align: justify;
                            color: #1A1A1A;
                            margin-bottom: setnum(40);
                        }
                        .btn{
                            display: block;
                            outline: none;
                            cursor: pointer;
                            width: setnum(120);
                            height: setnum(40);
                            line-height: setnum(38);
                            background: #FFFFFF;
                            border: setnum(1) solid #1A1A1A;
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(16);
                            color: #1A1A1A;
                        }
                        .btn:hover{
                            transition: $tst;
                            background: #1A1A1A;
                            color: #FFF;
                        }
                    }
                    
                }
            }
        }
    }
}

.normalh5{
    @include boxflex();
    flex-direction: column;
    justify-content: center;
    width: 100%;
    :global{
        .list{
            @include boxflex();
            justify-content: center;
            padding: 0 setnum(24);
            ul{
                @include boxflex();
                flex-direction: column;
                justify-content: center;
                background: #FFF;
                li{
                    background: #FFF;
                    width: 100%;
                    margin-bottom: setnum(80);
                    .pic{
                        width: 100%;
                        height: setnum(206);
                        margin-bottom: setnum(16);
                    }
                    .content{
                        width: 100%;
                        .title{
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(20);
                            line-height: setnum(28);
                            color: #1A1A1A;
                            margin-bottom: setnum(8);
                        }
                        .titleen{
                            //font-family: Lato;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(13);
                            line-height: setnum(20);
                            color: #1A1A1A;
                            margin-bottom: setnum(5);
                        }
                        .subtitle{
                            font-family: Lato;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(13);
                            line-height: setnum(20);
                            color: #1A1A1A;
                            opacity: 0.3;
                            margin-bottom: setnum(24);
                            i{
                                display: inline-block;
                                width: setnum(1);
                                height: setnum(12);
                                opacity: 0.3;
                                background: #1A1A1A;
                                margin: 0 setnum(10);
                            }
                        }
                        .desc{
                            //width: setnum(590);
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(28);
                            //text-align: justify;
                            color: #1A1A1A;
                            margin-bottom: setnum(24);
                        }
                        .btn{
                            display: block;
                            outline: none;
                            cursor: pointer;
                            width: setnum(96);
                            height: setnum(38);
                            line-height: setnum(36);
                            background: #FFFFFF;
                            border: setnum(1) solid #1A1A1A;
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: setnum(16);
                            color: #1A1A1A;
                            margin: 0 auto;
                        }
                        .btn:hover{
                            transition: $tst;
                            background: #1A1A1A;
                            color: #FFF;
                        }
                    }

                }
                li:last-child{
                    margin-bottom: setnum(48);
                }
            }
        }
    }
}