@import '../../../../global.module';

/*.svgPath {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: pink;
    stroke: deeppink;
    stroke-miterlimit: 10;
}*/


// 引用动画 start
.svgPath {
    @include SvgPaths(transparent, rgba(204, 204, 204, 1));
}
.svgPathAnimation {
    @include SvgPathAnimations(166);
}
// 引用动画 end

.normal{
    @include boxflex();
    //flex-direction: column;
    justify-content: center;
    :global{

    }
}

.normalM{
    @include boxflex();
    //flex-direction: column;
    justify-content: center;
    width: 100%;
    :global{

    }
}