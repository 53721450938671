@import '../../global.module';

.normal{
    width: 100%;
    height: 100vh;
    :global{
        .video{
            width: 100%;
            height: 100vh;
        }
        .video::-webkit-media-controls-fullscrenn-button{
            display: none;
        }
        .video::-webkit-media-controls-play-button{
            display: none;
        }
        .video::-webkit-media-controls-timeline{
            display: none;
        }
        .video::-webkit-media-controls-current-time-display{
            display: none;
        }
        .video::-webkit-media-controls-current-time-remaining-display{
            display: none;
        }
        .video::-webkit-media-controls-mute-button{
            display: none;
        }
        .video::-webkit-media-controls-toggle-closed-captions-button{
            display: none;
        }
        .video::-webkit-media-controls-volume-slider{
            display: none;
        }
        .video::-webkit-media-controls-enclosure{
            display: none;
        }



    }
}

.normalH5{
    :global{
        .video{
            width: 100%;
            height: 100vh;
        }
        .video::-webkit-media-controls-fullscrenn-button{
            display: none;
        }
        .video::-webkit-media-controls-play-button{
            display: none;
        }
        .video::-webkit-media-controls-timeline{
            display: none;
        }
        .video::-webkit-media-controls-current-time-display{
            display: none;
        }
        .video::-webkit-media-controls-current-time-remaining-display{
            display: none;
        }
        .video::-webkit-media-controls-mute-button{
            display: none;
        }
        .video::-webkit-media-controls-toggle-closed-captions-button{
            display: none;
        }
        .video::-webkit-media-controls-volume-slider{
            display: none;
        }
        .video::-webkit-media-controls-enclosure{
            display: none;
        }
    }
}