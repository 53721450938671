@import '../../global.module';

.normalMax{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            .content{
                margin-bottom: setnum(72);
                .rowb{
                    padding-left: setnum(657);
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: setnum(72);
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }
        
                    }
                }
            }

            .list{
                margin-bottom: setnum(150);
                ul{
                    @include boxflex();
                    justify-content: center;
                    li{
                        width: setnum(200);
                        @include boxflex();
                        flex-direction: column;
                        justify-content: center;
                        margin: 0 setnum(150);
                        .icon{
                            width: setnum(200);
                            height: setnum(200);
                            margin-bottom: setnum(8);
                            img{
                                width: setnum(200);
                                height: setnum(200);
                            }
                        }
                        .desc{
                            p:first-child{
                                height: setnum(28);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(20);
                                line-height: setnum(28);
                                text-align: center;
                                color: #1A1A1A;
                                margin-bottom: setnum(8);
                                white-space:nowrap;
                            }
                            p:last-child{
                                height: setnum(22);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(14);
                                line-height: setnum(22);
                                text-align: center;
                                color: #1A1A1A;
                                opacity: 0.6;
                                cursor: pointer;
                            }
                        }
                    }

                }
            }

        }
        .cardb{
            background: #F7F5F4;
            padding: setnum(56) 0 setnum(120) 0;
        }
    }
}

.normal{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            .content{
                .rowb{
                    padding-left: setnum(657);
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: setnum(72);
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }

                    }
                }
            }

            .list{
                margin-bottom: setnum(150);
                ul{
                    @include boxflex();
                    justify-content: center;
                    li{
                        width: setnum(200);
                        @include boxflex();
                        flex-direction: column;
                        justify-content: center;
                        margin: 0 setnum(150);
                        .icon{
                            width: setnum(200);
                            height: setnum(200);
                            margin-bottom: setnum(8);
                            img{
                                width: setnum(200);
                                height: setnum(200);
                            }
                        }
                        .desc{
                            p:first-child{
                                height: setnum(28);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(20);
                                line-height: setnum(28);
                                text-align: center;
                                color: #1A1A1A;
                                margin-bottom: setnum(8);
                                white-space:nowrap;
                            }
                            p:last-child{
                                height: setnum(22);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(14);
                                line-height: setnum(22);
                                text-align: center;
                                color: #1A1A1A;
                                opacity: 0.6;
                                cursor: pointer;
                            }
                        }
                    }

                }
            }

        }
        .cardb{
            background: #F7F5F4;
            padding: setnum(56) 0 setnum(120) 0;
        }
    }
}

.normalh5{
    background: #FFF;
    overflow: hidden;
    :global{
        .carda{
            .content{
                .rowb{
                    padding-left: setnum(657);
                    .ccb{
                        @include boxflex();
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: setnum(72);
                        .title{
                            font-weight: 500;
                            font-size: setnum(20);
                            height: setnum(28);
                            line-height: setnum(28);
                            text-align: left;
                            color: #B0A27C;
                            margin-bottom: setnum(16);
                        }
                        .subtitle{
                            font-weight: bold;
                            font-size: setnum(56);
                            text-align: left;
                            color: #1A1A1A;
                            margin-bottom: setnum(32);
                            span{
                                display: block;
                                height: setnum(80);
                                line-height: setnum(80);
                            }
                        }
                        .rectangle{
                            width: setnum(40);
                            height: setnum(6);
                            background: #1A1A1A;
                        }

                    }
                }
            }

            .list{
                margin-bottom: setnum(150);
                ul{
                    @include boxflex();
                    flex-direction: column;
                    justify-content: center;
                    li{
                        width: setnum(200);
                        @include boxflex();
                        flex-direction: column;
                        justify-content: center;
                        margin-bottom: setnum(56);
                        .icon{
                            width: setnum(200);
                            height: setnum(200);
                            margin-bottom: setnum(8);
                            img{
                                width: setnum(200);
                                height: setnum(200);
                            }
                        }
                        .desc{
                            p:first-child{
                                height: setnum(28);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(20);
                                line-height: setnum(28);
                                text-align: center;
                                color: #1A1A1A;
                                margin-bottom: setnum(8);
                                white-space:nowrap;
                            }
                            p:last-child{
                                height: setnum(22);
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: 500;
                                font-size: setnum(14);
                                line-height: setnum(22);
                                text-align: center;
                                color: #1A1A1A;
                                opacity: 0.6;
                                cursor: pointer;
                            }
                        }
                    }
                    li:last-child{
                        margin-bottom: 0;
                    }

                }
            }

        }
        .cardb{
            background: #F7F5F4;
            padding: setnum(56) 0 setnum(72) 0;
        }
    }
}
