@import '../../global.module';

.normalMax{
    overflow: hidden;
    :global{
        .one{
            background: linear-gradient(180deg, #F7F5F4 0%, rgba(247, 245, 244, 100) 100%);
            @include boxflex();
            justify-content: center;
            align-items: baseline;
            position: relative;
            overflow: hidden;
            width: 100vw;
            height: 100vh;
            .groupScroll{
                position: absolute;
                bottom: setnum(0);
                left: setnum(40);
            }
            .content{
                height: 100vh;
                width: 100vw;
                .slogan{
                    width: setnum(490);
                    height: setnum(42);
                    position: absolute;
                    bottom: setnum(150);
                    left: 50%;
                    margin-left: setnum(-254);
                    img{
                        width: setnum(490);
                        height: setnum(42);
                    }
                }
            }


        }
        .two{
            height: setnum(908);
            position: relative;
            overflow: hidden;
            background: #FFF;
            .left{
                position: absolute;
                top: setnum(212);
                left: setnum(220);
            }
            .right{
                position: absolute;
                top: setnum(168.6);
                right: setnum(100);
                .content{
                    width: setnum(830);
                    height: setnum(650);
                    position: relative;
                    top: 0;
                    .aboutlogo{
                        width: setnum(830);
                        height: setnum(650);
                        img{
                            width: setnum(830);
                            height: setnum(650);
                        }
                    }
                    .desc{
                        position: absolute;
                        top: setnum(271);
                        left: setnum(10);
                        width: setnum(500);
                        p{
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            opacity: 0.6;
                            color: #1A1A1A;
                        }
                    }
                }
            }

            .borderTextBox{
                @include boxflex();
                justify-content: flex-start;
                align-items: center;
                position: absolute;
                left: setnum(-60);
                bottom: setnum(211);
            }

            .btn{
                width: setnum(120);
                height: setnum(40);
                line-height: setnum(40);
                font-weight: 500;
                font-size: setnum(16);
                text-align: center;
                color: #1A1A1A;
                background: #FFFFFF;
                border: setnum(1) solid #1A1A1A;
                cursor: pointer;
                position: absolute;
                left: setnum(220);
                bottom: setnum(120);
            }
            .btn:hover{
                transition: $tst;
                color: #FFFFFF;
                background: #1A1A1A;
            }

        }
        .three{
            //height: setnum(1230);
            background: #F7F5F4;
            overflow: hidden;
            position: relative;
            top: 0;
            .pi{
                padding-top: setnum(130);
                margin-bottom: setnum(72);
                @include boxflex();
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding-left: setnum(970);
                z-index: 2;
            }
            .gamesBorderTextBox{
                position: absolute;
                left: setnum(169);
                top: setnum(354);
                z-index: 2;
                width: setnum($hMax);
                height: setnum(891);
            }

            .gamesbox{
                width: setnum(960);
                margin: 0 auto setnum(45) setnum(448) ;
                position: relative;
                z-index: 2;
                .content{
                    .imgbox{
                        height: setnum(448);
                        margin-bottom: setnum(32);
                        img{
                            width: 100%;
                            height: setnum(448);
                        }
                    }
                    .descbox{
                        @include boxflex();
                        align-items: baseline;
                        .left{
                            @include boxflex();
                            align-items: flex-start;
                            flex-direction: column;
                            margin-right: setnum(207);
                            .desctitle{
                                font-weight: 500;
                                height: setnum(40);
                                font-size: setnum(32);
                                line-height: setnum(40);
                                color: #1A1A1A;
                                margin-bottom: setnum(24);
                            }
                            .line{
                                width: setnum(20);
                                height: setnum(4);
                                display: inline-block;
                                background: #B0A27C;
                                margin-left: 0;
                                margin-bottom: setnum(24);
                            }
                            .desctext{
                                width: setnum(440);
                                font-weight: 500;
                                font-size: setnum(14);
                                line-height: setnum(32);
                                color: #1A1A1A;
                                margin-bottom: setnum(72);
                            }

                            .gbtn{
                                width: setnum(120);
                                height: setnum(40);
                                line-height: setnum(40);
                                font-weight: 500;
                                font-size: setnum(16);
                                text-align: center;
                                color: #1A1A1A;
                                background: transparent;
                                border: setnum(1) solid #1A1A1A;
                                cursor: pointer;
                            }
                            .gbtn:hover{
                                transition: $tst;
                                color: #FFFFFF;
                                background: #1A1A1A;
                            }
                        }
                        .right{
                            @include boxflex();
                            flex-direction: column;
                            align-items: flex-start;
                            width: setnum(345);

                            .rleft{
                                margin-bottom: setnum(32);
                                .ltype{
                                    font-weight: normal;
                                    height: setnum(22);
                                    font-size: setnum(14);
                                    line-height: setnum(22);
                                    color: #1A1A1A;
                                    opacity: 0.6;
                                    margin-bottom: setnum(8);
                                }
                                .lshooting, .lmultiplayer{
                                    height: setnum(20);
                                    font-size: setnum(14);
                                    line-height: setnum(20);
                                    font-weight: 500;
                                    color: #1A1A1A;
                                }
                            }
                        }
                    }

                }
            }
            .gamestabbox{
                width: setnum(260);
                background: $bgTran;
                position: absolute;
                right: 0;
                bottom: 0;

                .mathnum{
                    margin-left: setnum(40);
                    margin-bottom: setnum(16);
                    .next, .total{
                        width: setnum(28);
                        height: setnum(30);
                        display: inline-block;
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        text-align: center;
                        text-transform: uppercase;
                        color: #1A1A1A;
                    }
                    .total{
                        opacity: 0.3;
                    }
                    .botline{
                        display: inline-block;
                        width: setnum(60);
                        height: setnum(2);
                        background: #1A1A1A;
                        opacity: 0.3;
                        margin: 0 setnum(8);
                    }
                }
                .tabs{
                    .item{
                        position: relative;
                        width: setnum(260);
                        height: setnum(140);
                        cursor: pointer;
                        transition: all 0.5s;
                        .ititle{
                            position: absolute;
                            left: setnum(40);
                            bottom: setnum(16);
                            font-weight: 500;
                            font-size: setnum(14);
                            color: #FFF;
                            background: initial !important;
                        }
                        &:hover{
                            width: setnum(325);
                            margin-left: setnum(-55);
                        }
                    }
                    .tabactive{
                        width: setnum(325);
                        margin-left: setnum(-55);
                    }

                }
                .more{
                    padding: setnum(51) setnum(0) setnum(116) setnum(0);
                    font-weight: 500;
                    font-size: setnum(16);
                    line-height: setnum(24);
                    text-align: center;
                    color: #1A1A1A;
                    cursor: pointer;
                    .moreAnimation{
                        height: setnum(24);
                        min-height: 24px;
                        overflow: hidden;
                        .scrollText{
                            transition: all 0.5s;
                            transform: translate(0%, 0%);
                            span{
                                display: block;
                            }
                            span:first-child{
                                margin-bottom: setnum(10);
                            }
                        }
                        .scrollText:hover{
                            transform: translate(0%, -58%);
                        }
                    }

                }
            }
        }
        .four{
            padding: setnum(56) 0 setnum(120) setnum(184);
            .gamesBorderTextBox{
                width: setnum(1722);
                height: setnum($hMax);
                margin-bottom: setnum(50);
                margin-left: setnum(268);
            }
            .content{
                padding: 0 0 0 setnum(265);
                margin-bottom: setnum(72);
            }
            .tabs{
                @include boxflex();
                align-items: flex-end;
                justify-content: flex-start;
                .tleft{
                    @include boxflex();
                    padding-bottom: setnum(36);
                    .tfirst, .tnext{
                        @include boxflex();
                        justify-content: center;
                        width: setnum(58);
                        height: setnum(58);
                        cursor: pointer;
                        transition: all 0.5s;
                    }
                    .tfirst{
                        background: #FFFFFF;
                        opacity: 0.3;
                        border: setnum(1) solid #1A1A1A;
                        margin-right: setnum(12);
                    }
                    .tnext{
                        background: #FFFFFF;
                        border: setnum(1) solid #1A1A1A;
                        margin-left: setnum(12);
                    }
                    .tfirst > img, .tnext > img{
                        width: setnum(7.41);
                        height: setnum(12);
                    }
                    .tNextFirstActionWith{
                        background: #FFFFFF;
                    }
                    .tNextFirstAction{
                        opacity: 1 !important;
                        background: #1A1A1A;
                        border: setnum(1) solid #1A1A1A;
                    }
                }
                .tright{
                    margin-left: setnum(32);
                    .tlist{
                        @include boxflex();
                        justify-content: flex-start;
                        width: auto;
                        margin-bottom: setnum(28);
                        padding: 0  setnum(40);
                        .titem{
                            margin-right: setnum(32);
                            cursor: pointer;
                            width: setnum(395);
                            box-sizing: border-box;
                            .timg{
                                width: setnum(395);
                                height: setnum(220);
                                background: green;
                                img{
                                    width: setnum(395);
                                    height: setnum(220);
                                }
                            }
                            .tdesc{
                                padding: setnum(28) setnum(16);
                                @include boxflex();
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                .tag{
                                    height: setnum(28);
                                    width: setnum(72);
                                    min-width: setnum(72);
                                    line-height: setnum(28);
                                    background: #1A1A1A;
                                    text-align: center;
                                    margin-bottom: setnum(16);
                                    span{
                                        font-size: setnum(14);
                                        color: #FFFFFF;
                                    }
                                }
                                .ttext{
                                    height: setnum(56);
                                    font-weight: 500;
                                    font-size: setnum(20);
                                    line-height: setnum(28);
                                    color: #1A1A1A;
                                    //word-break: break-all;
                                    margin-bottom: setnum(24);
                                    @include textEllipsis(2);
                                }
                                .ttime{
                                    font-size: setnum(12);
                                    height: setnum(16);
                                    color: #1A1A1A;
                                    opacity: 0.6;
                                }
                            }
                        }
                        .titem:hover{
                            background: #FFFFFF;
                            box-shadow: 0 setnum(10) setnum(50) rgba(176, 162, 124, 0.2);
                        }
                        .titem:last-child{
                            margin-right: 0;
                        }

                    }

                    /* 隐藏滚动条 */
                    /*.tlist::-webkit-scrollbar {
                        display: none;
                    }*/

                    .tprogre{
                        width: setnum(200);
                        height: setnum(2);
                        background: #e8e8e8;
                        overflow: hidden;
                        .tbar{
                            transition: all 0.5s;
                            width: setnum(60);
                            height: setnum(2);
                            background: #1b1b1b;
                        }
                    }

                }

            }
        }

        .five{
            background: #000;
            padding: setnum(56) 0 setnum(150) 0;

            animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
            animation-duration: 1.2s;
            animation-fill-mode: both;

            .gamesBorderTextBox{
                opacity: 1;
                width: setnum(2206);
                height: setnum($hMax);
                margin-left: setnum(-82);
                margin-bottom: setnum(50);
            }
            .content{
                padding: 0 setnum(220) 0 0;
                @include boxflex();
                flex-direction: column;
                align-items: flex-end;
                margin-bottom: setnum(72);
            }
            .ics{
                @include boxflex();
                justify-content: center;
                .iitem{
                    color: #FFFFFF;
                    width: setnum(200);
                    margin: 0 setnum(170);
                    .iicon{
                        width: setnum(200);
                        height: setnum(200);
                        @include boxflex();
                        justify-content: center;
                        img{
                            width: setnum(100);
                            height: setnum(100);
                        }
                    }
                    .ititle{
                        height: setnum(28);
                        font-weight: 500;
                        font-size: setnum(20);
                        line-height: setnum(28);
                        text-align: center;
                        color: #FFFFFF;
                        margin-bottom: setnum(8);
                    }
                    .iemail{
                        height: setnum(20);
                        font-weight: 500;
                        font-size: setnum(14);
                        line-height: setnum(20);
                        text-align: center;
                        color: #FFFFFF;
                        cursor: pointer;
                    }

                }
            }
        }
        .six{
            padding: setnum(56) 0 setnum(120) 0;
            background: #F7F5F4;
        }

    }

}

.normal{
    overflow: hidden;
    :global{
        .one{
            background: linear-gradient(180deg, #F7F5F4 0%, rgba(247, 245, 244, 100) 100%);
            @include boxflex();
            justify-content: center;
            align-items: baseline;
            position: relative;
            overflow: hidden;
            width: 100vw;
            height: 100vh;
            .groupScroll{
                position: absolute;
                bottom: setnum(0);
                left: setnum(40);
            }
            .content{
                height: 100vh;
                width: 100vw;
                .slogan{
                    width: setnum(490);
                    height: setnum(42);
                    position: absolute;
                    bottom: setnum(150);
                    left: 50%;
                    margin-left: setnum(-254);
                    img{
                        width: setnum(490);
                        height: setnum(42);
                    }
                }
            }


        }
        .two{
            height: setnum(908);
            position: relative;
            overflow: hidden;
            background: #FFF;
            .left{
                position: absolute;
                top: setnum(212);
                left: setnum(80);
            }
            .right{
                position: absolute;
                top: setnum(168.6);
                right: setnum(81.3);
                .content{
                    width: setnum(743.9);
                    height: setnum(578.61);
                    position: relative;
                    top: 0;
                    .aboutlogo{
                        width: setnum(743.9);
                        height: setnum(578.61);
                        img{
                            width: setnum(743.9);
                            height: setnum(578.61);
                        }
                    }
                    .desc{
                        position: absolute;
                        top: setnum(271.4);
                        left: setnum(93.2);
                        width: setnum(500);
                        p{
                            font-weight: normal;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            opacity: 0.6;
                            color: #1A1A1A;
                        }
                    }
                }
            }

            .borderTextBox{
                @include boxflex();
                justify-content: flex-start;
                align-items: center;
                position: absolute;
                left: setnum(-46);
                bottom: setnum(211);
            }

            .btn{
                width: setnum(120);
                height: setnum(40);
                line-height: setnum(40);
                font-weight: 500;
                font-size: setnum(16);
                text-align: center;
                color: #1A1A1A;
                background: #FFFFFF;
                border: setnum(1) solid #1A1A1A;
                cursor: pointer;
                position: absolute;
                left: setnum(80);
                bottom: setnum(120);
            }
            .btn:hover{
                transition: $tst;
                color: #FFFFFF;
                background: #1A1A1A;
            }

        }
        .three{
            height: setnum(1170);
            background: #F7F5F4;
            overflow: hidden;
            position: relative;
            top: 0;
            .pi{
                padding-top: setnum(130);
                margin-bottom: setnum(72);
                @include boxflex();
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding-left: setnum(730);
                z-index: 2;
            }
            .gamesBorderTextBox{
                position: absolute;
                left: setnum(117);
                top: setnum(354);
                z-index: 2;
            }

            .gamesbox{
                width: setnum(832);
                margin: 0 auto setnum(45) setnum(304) ;
                position: relative;
                z-index: 2;
                .content{
                    .imgbox{
                        background: red;
                        height: setnum(388);
                        margin-bottom: setnum(32);
                        img{
                            width: 100%;
                            height: setnum(388);
                        }
                    }
                    .descbox{
                        @include boxflex();
                        align-items: baseline;
                        .left{
                            @include boxflex();
                            align-items: flex-start;
                            flex-direction: column;
                            margin-right: setnum(114);
                            .desctitle{
                                font-weight: 500;
                                height: setnum(40);
                                font-size: setnum(32);
                                line-height: setnum(40);
                                color: #1A1A1A;
                                margin-bottom: setnum(24);
                            }
                            .line{
                                width: setnum(20);
                                height: setnum(4);
                                display: inline-block;
                                background: #B0A27C;
                                margin-left: 0;
                                margin-bottom: setnum(24);
                            }
                            .desctext{
                                width: setnum(420);
                                font-weight: 500;
                                font-size: setnum(14);
                                line-height: setnum(32);
                                color: #1A1A1A;
                                margin-bottom: setnum(72);
                            }

                            .gbtn{
                                width: setnum(120);
                                height: setnum(40);
                                line-height: setnum(40);
                                font-weight: 500;
                                font-size: setnum(16);
                                text-align: center;
                                color: #1A1A1A;
                                background: transparent;
                                border: setnum(1) solid #1A1A1A;
                                cursor: pointer;
                            }
                            .gbtn:hover{
                                transition: $tst;
                                color: #FFFFFF;
                                background: #1A1A1A;
                            }
                        }
                        .right{
                            @include boxflex();
                            flex-direction: column;
                            align-items: flex-start;
                            width: setnum(345);

                            .rleft{
                                margin-bottom: setnum(32);
                                .ltype{
                                    font-weight: normal;
                                    height: setnum(22);
                                    font-size: setnum(14);
                                    line-height: setnum(22);
                                    color: #1A1A1A;
                                    opacity: 0.6;
                                    margin-bottom: setnum(8);
                                }
                                .lshooting, .lmultiplayer{
                                    height: setnum(20);
                                    font-size: setnum(14);
                                    line-height: setnum(20);
                                    font-weight: 500;
                                    color: #1A1A1A;
                                }
                            }
                        }
                    }
                    
                }
            }
            .gamestabbox{
                width: setnum(222);
                background: $bgTran;
                position: absolute;
                right: 0;
                bottom: 0;
                
                .mathnum{
                    margin-left: setnum(40);
                    margin-bottom: setnum(16);
                    .next, .total{
                        width: setnum(28);
                        height: setnum(30);
                        display: inline-block;
                        font-weight: 500;
                        font-size: setnum(24);
                        line-height: setnum(30);
                        text-align: center;
                        text-transform: uppercase;
                        color: #1A1A1A;
                    }
                    .total{
                        opacity: 0.3;
                    }
                    .botline{
                        display: inline-block;
                        width: setnum(60);
                        height: setnum(2);
                        background: #1A1A1A;
                        opacity: 0.3;
                        margin: 0 setnum(8);
                    }
                }
                .tabs{
                    .item{
                        position: relative;
                        width: setnum(222);
                        height: setnum(125);
                        cursor: pointer;
                        transition: all 0.5s;
                        .ititle{
                            position: absolute;
                            left: setnum(40);
                            bottom: setnum(16);
                            font-weight: 500;
                            font-size: setnum(14);
                            color: #FFF;
                            background: initial !important;
                        }
                        &:hover{
                            width: setnum(277);
                            margin-left: setnum(-55);
                        }
                    }
                    .tabactive{
                        width: setnum(277);
                        margin-left: setnum(-55);
                    }

                }
                .more{
                    padding: setnum(51) setnum(0) setnum(116) setnum(0);
                    font-weight: 500;
                    font-size: setnum(16);
                    line-height: setnum(24);
                    text-align: center;
                    color: #1A1A1A;
                    cursor: pointer;
                    .moreAnimation{
                        height: setnum(24);
                        min-height: 24px;
                        overflow: hidden;
                        .scrollText{
                            transition: all 0.5s;
                            transform: translate(0%, 0%);
                            span{
                                display: block;
                            }
                            span:first-child{
                                margin-bottom: setnum(10);
                            }
                        }
                        .scrollText:hover{
                            transform: translate(0%, -58%);
                        }
                    }

                }
            }
        }
        .four{
            padding: setnum(56) 0 setnum(120) setnum(43);
            .gamesBorderTextBox{
                width: setnum(1272.9);
                height: setnum(134.01);
                margin-bottom: setnum(50);
                margin-left: setnum(298);
            }
            .content{
                padding: 0 0 0 setnum(296);
                margin-bottom: setnum(72);
            }
            .tabs{
                @include boxflex();
                align-items: flex-end;
                justify-content: flex-start;
                .tleft{
                    @include boxflex();
                    padding-bottom: setnum(36);
                    .tfirst, .tnext{
                        @include boxflex();
                        justify-content: center;
                        width: setnum(58);
                        height: setnum(58);
                        cursor: pointer;
                        transition: all 0.5s;
                    }
                    .tfirst{
                        background: #FFFFFF;
                        opacity: 0.3;
                        border: setnum(1) solid #1A1A1A;
                        margin-right: setnum(12);
                    }
                    .tnext{
                        background: #FFFFFF;
                        border: setnum(1) solid #1A1A1A;
                        margin-left: setnum(12);
                    }
                    .tfirst > img, .tnext > img{
                        width: setnum(7.41);
                        height: setnum(12);
                    }
                    .tNextFirstActionWith{
                        background: #FFFFFF;
                    }
                    .tNextFirstAction{
                        opacity: 1 !important;
                        background: #1A1A1A;
                        border: setnum(1) solid #1A1A1A;
                    }
                }
                .tright{
                    margin-left: setnum(32);
                    .tlist{
                        @include boxflex();
                        justify-content: flex-start;
                        width: auto;
                        margin-bottom: setnum(28);
                        padding: 0  setnum(40);
                        .titem{
                            margin-right: setnum(32);
                            cursor: pointer;
                            width: setnum(325);
                            box-sizing: border-box;
                            .timg{
                                width: setnum(325);
                                height: setnum(180);
                                background: green;
                                img{
                                    width: setnum(325);
                                    height: setnum(180);
                                }
                            }
                            .tdesc{
                                padding: setnum(28) setnum(16);
                                @include boxflex();
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                .tag{
                                    height: setnum(28);
                                    width: setnum(72);
                                    min-width: setnum(72);
                                    line-height: setnum(28);
                                    background: #1A1A1A;
                                    text-align: center;
                                    margin-bottom: setnum(16);
                                    span{
                                        font-size: setnum(14);
                                        color: #FFFFFF;
                                    }
                                }
                                .ttext{
                                    height: setnum(56);
                                    font-weight: 500;
                                    font-size: setnum(20);
                                    line-height: setnum(28);
                                    color: #1A1A1A;
                                    //word-break: break-all;
                                    margin-bottom: setnum(24);
                                    @include textEllipsis(2);
                                }
                                .ttime{
                                    font-size: setnum(12);
                                    height: setnum(16);
                                    color: #1A1A1A;
                                    opacity: 0.6;
                                }
                            }
                        }
                        .titem:hover{
                            background: #FFFFFF;
                            box-shadow: 0 setnum(10) setnum(50) rgba(176, 162, 124, 0.2);
                        }
                        .titem:last-child{
                            margin-right: 0;
                        }
                       
                    }

                    /* 隐藏滚动条 */
                    /*.tlist::-webkit-scrollbar {
                        display: none;
                    }*/

                    .tprogre{
                        width: setnum(200);
                        height: setnum(2);
                        background: #e8e8e8;
                        overflow: hidden;
                        .tbar{
                            transition: all 0.5s;
                            width: setnum(60);
                            height: setnum(2);
                            background: #1b1b1b;
                        }
                    }
                    
                }
                
            }
        }

        .five{
            background: #000;
            padding: setnum(56) 0 setnum(150) 0;

            animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
            animation-duration: 1.2s;
            animation-fill-mode: both;

            .gamesBorderTextBox{
                opacity: 1;
                width: setnum(1635.27);
                height: setnum(134.01);
                margin-left: setnum(-42);
                margin-bottom: setnum(50);
            }
            .content{
                padding: 0 setnum(80) 0 0;
                @include boxflex();
                flex-direction: column;
                align-items: flex-end;
                margin-bottom: setnum(72);
            }
            .ics{
                @include boxflex();
                justify-content: center;
                .iitem{
                    color: #FFFFFF;
                    width: setnum(200);
                    margin: 0 setnum(135);
                    .iicon{
                        width: setnum(200);
                        height: setnum(200);
                        @include boxflex();
                        justify-content: center;
                        img{
                            width: setnum(100);
                            height: setnum(100);
                        }
                    }
                    .ititle{
                        height: setnum(28);
                        font-weight: 500;
                        font-size: setnum(20);
                        line-height: setnum(28);
                        text-align: center;
                        color: #FFFFFF;
                        margin-bottom: setnum(8);
                    }
                    .iemail{
                        height: setnum(20);
                        font-weight: 500;
                        font-size: setnum(14);
                        line-height: setnum(20);
                        text-align: center;
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                    
                }
            }
        }
        .six{
            padding: setnum(56) 0 setnum(120) 0;
            background: #F7F5F4;
        }

    }
    
}

.normalH5{
    min-width: setnum(320);
    overflow: hidden;
    :global{
        .one{
            height: 100vh;
            // background: linear-gradient(180deg, #F7F5F4 0%, rgba(247, 245, 244, 100) 100%);
            background: linear-gradient(180deg, #f9f7f6 0%, #f9f7f6 100%);
            @include boxflex();
            justify-content: center;
            align-items: baseline;
            position: relative;
            overflow: hidden;
            .bot{
                position: absolute;
                bottom: setnum(0);
                width: 100%;
                background: red;
                height: setnum(115);
                z-index: 99;
                display: none;
            }
            .groupScroll{
                position: absolute;
                bottom: setnum(0);
                left: setnum(24);
            }
            .content{
                position: relative;
                width: 100vw;
                height: 100vh;
                .slogan{
                    width: setnum(300);
                    height: setnum(25.71);
                    position: absolute;
                    bottom: setnum(257.29+115);
                    left: 50%;
                    margin-left: setnum(-300/2);
                    img{
                        width: setnum(300);
                        height: setnum(25.71);
                    }
                }
            }
        }
        .two{
            padding: 0 setnum(24);
            position: relative;
            overflow: hidden;
            background: #FFF;
            .info{
                @include boxflex();
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .left{
                    margin-bottom: setnum(24);
                    .content{
                        padding: setnum(43) 0 0 0;
                    }
                }
                .right{
                    width: 100%;
                    .content{
                        @include boxflex();
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;
                        position: relative;
                        .aboutlogo{
                            width: setnum(256);
                            height: setnum(200);
                            img{
                                width: setnum(256);
                                height: setnum(200);
                            }
                        }
                        .desc{
                            width: setnum(294);
                            position: absolute;
                            top: setnum(14.77);
                            right: setnum(8);
                            //left: setnum(65);
                            p{
                                font-family: Noto Sans SC;
                                font-weight: normal;
                                font-size: setnum(16);
                                line-height: setnum(28);
                                opacity: 0.6;
                                color: #1A1A1A;
                            }
                            .latoFont{
                                font-family: Lato;
                                font-style: normal;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }

            .borderTextBox{
                @include boxflex();
                justify-content: flex-start;
                align-items: center;
                position: absolute;
                left: -6%;
                bottom: 25%;
                .xiang{
                    margin-left: setnum(0);
                }
            }

            .btn{
                width: setnum(96);
                height: setnum(38);
                line-height: setnum(38);
                font-weight: 500;
                font-size: setnum(16);
                text-align: center;
                color: #1A1A1A;
                background: #FFFFFF;
                border: setnum(1) solid #1A1A1A;
                cursor: pointer;
                margin: setnum(33) auto setnum(72) auto;
            }
            .btn:hover{
                transition: $tst;
                color: #FFFFFF;
                background: #1A1A1A;
            }

        }
        .three{
            background: #F7F5F4;
            overflow: hidden;
            position: relative;
            .pi{
                padding: setnum(56) setnum(24) setnum(32) setnum(24);
                @include boxflex();
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                z-index: 2;
                .blockContents{
                    width: 100%;
                    @include boxflex();
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    position: relative;
                    z-index: 3;
                }

            }
            .gamesBorderTextBox{
                position: absolute;
                left: setnum(-25);
                top: setnum(122);
                z-index: 2;

            }

            .gamesbox{
                width: setnum(382);
                margin: 0 auto setnum(45+56) auto;
                position: relative;
                z-index: 2;
                .content{
                    .imgbox{
                        background: red;
                        height: setnum(178);
                        margin-bottom: setnum(32);
                        img{
                            width: 100%;
                            height: setnum(178);
                        }
                    }
                    .descbox{
                        @include boxflex();
                        flex-direction: column;
                        .left{
                            @include boxflex();
                            flex-direction: column;
                            align-items: flex-start;
                            margin-bottom: setnum(32);
                            .desctitle{
                                font-weight: 500;
                                height: setnum(28);
                                font-size: setnum(20);
                                line-height: setnum(28);
                                color: #1A1A1A;
                                margin-bottom: setnum(16);
                            }
                            .line{
                                width: setnum(16);
                                height: setnum(3);
                                display: inline-block;
                                background: #B0A27C;
                                margin-left: 0;
                                margin-bottom: setnum(16);
                            }
                            .desctext{
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: normal;
                                line-height: setnum(28);
                                text-align: justify;
                                width: setnum(366);
                                font-size: setnum(16);
                                //text-align: justify;
                                color: #1A1A1A;
                                margin-bottom: setnum(32);
                            }

                        }
                        .right{
                            width: 95.4%;
                            display: grid;
                            grid-template-columns: 50% 50%;
                            //flex-wrap: wrap;
                            margin-bottom: setnum(32);

                            .rleft{
                                width: setnum(167);
                                //margin: 0 setnum(8) setnum(16) setnum(8);
                                .ltype{
                                    font-weight: normal;
                                    height: setnum(20);
                                    font-size: setnum(13);
                                    line-height: setnum(18);
                                    color: #1A1A1A;
                                    opacity: 0.6;
                                    margin-bottom: setnum(8);
                                }
                                .lshooting, .lmultiplayer{
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-size: setnum(13);
                                    line-height: setnum(20);
                                    font-weight: 500;
                                    color: #1A1A1A;
                                    width: setnum(147);
                                    margin-bottom: setnum(16);
                                }
                            }
                            .rleft:nth-child(3) .ltype, .rleft:nth-child(4) .ltype{
                                font-size: setnum(14);
                                line-height: setnum(22);
                            }
                        }
                        .btnText{
                            width: 100%;
                            text-align: center;
                            .gbtn{
                                display: inline-block;
                                width: setnum(96);
                                height: setnum(38);
                                line-height: setnum(38);
                                background: #F7F5F4;
                                text-align: center;
                                border: setnum(1) solid #1A1A1A;
                                cursor: pointer;
                            }
                            .gbtn:hover{
                                transition: $tst;
                                background: #1A1A1A;
                                color: #FFF;
                            }
                        }
                    }


                }
            }
            .gamestabbox{
                background: $bgTran;
                @include boxflex();
                justify-content: flex-start;
                align-items: stretch;
                position: relative;
                z-index: 1;
                height: setnum(90);
                background: #E7E3D8;

                .mathnum{
                    padding-left: setnum(6);
                    @include boxflex();
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    //margin-top: setnum(30);
                    //margin-bottom: setnum(7);
                    background: #E7E3D8;
                    z-index: 4;
                    .next{
                        margin-bottom: setnum(8);
                    }
                    .next, .total{
                        width: setnum(20);
                        height: setnum(16);
                        display: inline-block;
                        font-weight: 500;
                        font-size: setnum(13);
                        line-height: setnum(16);
                        text-align: center;
                        text-transform: uppercase;
                        color: #1A1A1A;
                        transform: rotate(90deg);
                    }
                    .total{
                        opacity: 0.3;
                    }
                    .botline{
                        display: inline-block;
                        width: setnum(1);
                        height: setnum(16);
                        background: #1A1A1A;
                        opacity: 0.3;
                        margin-bottom: setnum(8);
                    }
                }


                /* 需要滚动条隐藏的列表 */
                .tabs{
                    width: setnum(400);
                    overflow: hidden;
                    overflow-x: scroll;
                    position: absolute;
                    bottom: setnum(24);
                    left: setnum(32);

                    .tabsBox{
                        @include boxflex();
                        justify-content: flex-start;
                        width: setnum(1035);
                        box-sizing: border-box;
                        .item{
                            position: relative;
                            width: setnum(160);
                            height: setnum(90);
                            cursor: pointer;
                            margin-right: setnum(16);
                            z-index: 4;
                            .ititle{
                                position: absolute;
                                left: setnum(8);
                                bottom: setnum(8);
                                font-weight: 500;
                                font-size: setnum(14);
                                color: #FFF;
                            }
                        }
                        .item:last-child{
                            margin-right: setnum(0);
                        }
                        .tabactive{
                            width: setnum(160);
                        }
                        .more{
                            width: setnum(160);
                            height: setnum(90);
                            font-weight: 500;
                            font-size: setnum(16);
                            line-height: setnum(90);
                            text-align: center;
                            color: #FFF;
                            cursor: pointer;
                            background: #464646;
                            z-index: 4;
                        }
                    }
                }
                /* 隐藏滚动条 */
                .tabs::-webkit-scrollbar {
                    display: none;
                }

            }
        }
        .four{
            padding: setnum(56) 0;
            .gamesBorderTextBox{
                width: setnum(402);
                height: setnum(68);
                margin-left: setnum(24);
                margin-bottom: setnum(16);
            }
            .content{
                padding: 0  setnum(24);
                margin-bottom: setnum(32);
            }
            .tabs{
                @include boxflex();
                align-items: flex-end;
                justify-content: flex-start;
                .tleft{
                    @include boxflex();
                    padding-bottom: setnum(36);
                    .tfirst, .tnext{
                        @include boxflex();
                        justify-content: center;
                        width: setnum(58);
                        height: setnum(58);
                        cursor: pointer;
                    }
                    .tfirst{
                        background: #FFFFFF;
                        opacity: 0.3;
                        border: setnum(1) solid #1A1A1A;
                        margin-right: setnum(12);
                    }
                    .tnext{
                        background: #FFFFFF;
                        border: setnum(1) solid #1A1A1A;
                        margin-left: setnum(12);
                    }
                    .tfirst > img, .tnext > img{
                        width: setnum(7.41);
                        height: setnum(12);
                    }
                }
                .tright{
                    min-width: setnum(414);
                    //overflow: hidden;
                    overflow-x: scroll;
                    padding:0 setnum(12);
                    .tlist{
                        @include boxflex();
                        justify-content: flex-start;
                        width: setnum(1330);
                        //width: auto;
                        margin-bottom: setnum(28);
                        .titem{
                            width: setnum(300);
                            margin: 0 setnum(12);
                            cursor: pointer;
                            .timg{
                                width: setnum(300);
                                height: setnum(167);
                                background: green;
                                img{
                                    width: setnum(300);
                                    height: setnum(167);
                                }
                            }
                            .tdesc{
                                padding: setnum(16) setnum(16);
                                @include boxflex();
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                .tag{
                                    height: setnum(22);
                                    width: setnum(68);
                                    min-width: setnum(68);
                                    line-height: setnum(22);
                                    background: #1A1A1A;
                                    text-align: center;
                                    margin-bottom: setnum(8);
                                    span{
                                        font-size: setnum(13);
                                        color: #FFFFFF;
                                    }
                                }
                                .ttext{
                                    height: setnum(44);
                                    font-weight: 500;
                                    font-size: setnum(16);
                                    line-height: setnum(22);
                                    color: #1A1A1A;
                                    word-break: break-all;
                                    margin-bottom: setnum(24);
                                }
                                .ttime{
                                    font-size: setnum(12);
                                    height: setnum(16);
                                    color: #1A1A1A;
                                    opacity: 0.6;
                                }
                            }
                        }
                        .titem:hover{
                            background: #FFFFFF;
                            box-shadow: 0px setnum(10) setnum(50) rgba(176, 162, 124, 0.2);
                        }
                    }

                    .tprogre{
                        width: setnum(200);
                        height: setnum(2);
                        background: #e8e8e8;
                        overflow: hidden;
                        .tbar{
                            transition: all 0.5s;
                            width: setnum(60);
                            height: setnum(2);
                            background: #1b1b1b;
                        }
                    }

                }
                /* 隐藏滚动条 */
                .tright::-webkit-scrollbar {
                    display: none;
                }

            }
        }
        .five{
            background: #1A1A1A;
            padding: setnum(56) 0 setnum(16) 0;

            animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
            animation-duration: 1.2s;
            animation-fill-mode: both;

            .gamesBorderTextBox{
                opacity: 1;
                margin-left: setnum(-15);
                margin-bottom: setnum(16);
            }
            .content{
                padding: 0 setnum(24) 0 0;
                @include boxflex();
                flex-direction: column;
                align-items: flex-end;
                margin-bottom: setnum(32);
            }
            .ics{
                @include boxflex();
                flex-direction: column;
                justify-content: center;
                .iitem{
                    color: #FFFFFF;
                    margin: 0 auto setnum(56) auto;
                    .iicon{
                        width: setnum(150);
                        height: setnum(150);
                        @include boxflex();
                        justify-content: center;
                        margin-bottom: setnum(8);
                        img{
                            width: setnum(150);
                            height: setnum(150);
                        }
                    }
                    .ititle{
                        height: setnum(28);
                        font-weight: 500;
                        font-size: setnum(20);
                        line-height: setnum(28);
                        text-align: center;
                        color: #FFFFFF;
                        margin-bottom: setnum(8);
                    }
                    .iemail{
                        height: setnum(20);
                        font-weight: 500;
                        font-size: setnum(13);
                        line-height: setnum(20);
                        text-align: center;
                        color: #FFFFFF;
                    }

                }
            }
        }
        .six{
            padding: setnum(56) 0 setnum(72) 0;
            background: #F7F5F4;
        }
    }
}
