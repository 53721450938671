@import '../../../global.module';

// 引用动画 start
.svgPath {
    @include SvgPaths(rgba(230, 227, 217, 1), rgba(204, 204, 204, 1));
}
.svgPathAnimation {
    @include SvgPathAnimations(0);
}
// 引用动画 end

.normal{
    @include boxflex();
    //flex-direction: column;
    justify-content: flex-start;
    :global{

    }
}

.normalM{
    @include boxflex();
    //flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    :global{

    }
}