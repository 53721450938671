@import '../../global.module';

.normal{
    :global {
        .title{
            font-weight: 500;
            font-size: setnum(20);
            color: #B0A27C;
            margin-bottom: setnum(16);
        }
        .text{
            margin-bottom: setnum(24);
            p{
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: setnum(56);
                line-height: setnum(80);
                color: #1A1A1A;
                display: block;
            }
        }
        .desc{
            p{
                height: setnum(32);
                line-height: setnum(32);
                font-family: Lato;
                font-style: normal;
                span:first-child{
                    font-style: normal;
                    font-weight: normal;
                    font-size: setnum(16);
                    color: #1A1A1A;
                }
                span:last-child{
                    margin-left: setnum(16);
                    color: #5E5E5E;
                }
            }
            .subline {
                width: setnum(40);
                height: setnum(6);
                background: #1A1A1A;
            }
        }
        
    }
}