@import '../../../global.module';

.normalMax{
    background: #FFF;
    overflow: hidden;
    @include boxflex();
    justify-content: center;
    position: relative;
    padding: 0 0 setnum(120) 0;
    :global{
        .textanimate{
            width: setnum(747);
            height: setnum($hMax);
            position: absolute;
            top: setnum(255);
            left: setnum(220);
            z-index: 0;
        }
        .content{
            z-index: 1;
            padding: setnum(336) 0 0 0;
            width: setnum(1200);
            @include boxflex();
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .info{
                position: relative;
                padding-top: setnum(533);
                @include boxflex();
                flex-direction: column;
                justify-content: flex-start;
                .top{
                    background: red;
                    width: setnum(1200);
                    height: setnum(668);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 3;
                }
                .cardbox{
                    width: setnum(1080);
                    box-sizing: border-box;
                    background: #FFF;
                    z-index: 4;
                    padding: setnum(40) setnum(40);
                    .headerTitle{
                        margin-bottom: setnum(40);
                        h1{
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: bold;
                            font-size: setnum(56);
                            line-height: setnum(80);
                            color: #1A1A1A;
                            text-align: left;
                        }
                        
                    }
                    .cardMain{
                        @include boxflex();
                        justify-content: flex-start;
                        //align-items: baseline;
                        align-items: initial;
                        margin-bottom: setnum(80);
                        .mleft{
                            margin-right: setnum(90);
                            .mrdatatime{
                                width: setnum(105);
                                .mrdyd{
                                    width: setnum(105);
                                    height: setnum(105);
                                    background: #1A1A1A;
                                    text-align: center;
                                    @include boxflex();
                                    flex-direction: column;
                                    justify-content: center;
                                    margin-bottom: setnum(16);
                                    p:first-child{
                                        font-family: Lato;
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: setnum(12);
                                        line-height: setnum(16);
                                        text-align: center;
                                        color: #FFFFFF;
                                        margin-bottom: setnum(8);
                                    }
                                    p:last-child{
                                        font-family: Lato;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: setnum(24);
                                        line-height: setnum(30);
                                        text-align: center;
                                        color: #FFFFFF;
                                    }

                                }
                                .mrtitle{
                                    p{
                                        font-family: Lato;
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: setnum(14);
                                        line-height: setnum(22);
                                        text-align: center;
                                        color: #B0A27C;
                                    }
                                }
                            }
                        }
                        .mright{
                            width: setnum(730);
                            padding-top: setnum(30);
                            box-sizing: border-box;
                            p{
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: normal;
                                font-size: setnum(16);
                                line-height: setnum(32);
                                color: #1A1A1A;
                                opacity: 0.8;
                                margin-bottom: setnum(40);
                            }
                            p:last-child{
                                margin-bottom: setnum(0);
                            }
                        }
                    }
                    
                }
                
            }
            .cardFooter{
                width: setnum(992);
                .line{
                    width: 100%;
                    height: setnum(1);
                    opacity: 0.1;
                    background: #1A1A1A;
                    margin-bottom: setnum(56);
                }
                .tabselect{
                    @include boxflex();
                    .previous, .next, .backtolist{
                        @include boxflex();
                        justify-content: center;
                        cursor: pointer;
                        .icon{
                            width: setnum(24);
                            height: setnum(24);
                            display: block;
                            @include boxflex();
                            justify-content: center;
                            img{
                                width: setnum(24);
                                height: setnum(24);
                            }
                        }
                        span{
                            font-family: Lato;
                            font-style: normal;
                            font-weight: bold;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            color: #1A1A1A;
                        }
                    }
                    .previous, .next{
                        .scrollAnimation{
                            height: setnum(24);
                            min-height: 24px;
                            overflow: hidden;
                            cursor: pointer;
                            .scrollText{
                                transition: all 0.5s;
                                transform: translate(0%, 0%);
                                .row{
                                    width: auto;
                                    @include boxflex();
                                    justify-content: center;
                                    cursor: pointer;
                                }
                            }
                            .scrollText:hover{
                                transform: translate(0%, -58%);
                            }
                        }
                    }
                    .backtolist{
                        .scrollAnimation{
                            height: setnum(32);
                            min-height: 32px;
                            overflow: hidden;
                            cursor: pointer;
                            padding-top: setnum(6);
                            .scrollText{
                                transition: all 0.5s;
                                transform: translate(0%, 0%);
                                span{
                                    display: block;
                                    font-family: Lato;
                                    font-style: normal;
                                    font-weight: bold;
                                    font-size: setnum(16);
                                    line-height: setnum(32);
                                    text-align: center;
                                    color: #1A1A1A;
                                    cursor: pointer;
                                }
                                span:first-child{
                                    margin-bottom: setnum(10);
                                }
                            }
                            .scrollText:hover{
                                transform: translate(0%, -58%);
                            }
                        }
                    }

                    .active{
                        color: #1A1A1A;
                        opacity: 0.3;
                    }
                }
            }
        }
    }
}

.normal{
    background: #FFF;
    overflow: hidden;
    @include boxflex();
    justify-content: center;
    position: relative;
    padding: 0 0 setnum(120) 0;
    :global{
        .textanimate{
            //width: setnum(747);
            //height: setnum(288);
            position: absolute;
            top: setnum(275);
            left: setnum(80);
            z-index: 0;
        }
        .content{
            z-index: 1;
            padding: setnum(336) 0 0 0;
            width: setnum(992);
            @include boxflex();
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .info{
                position: relative;
                padding-top: setnum(533);
                @include boxflex();
                flex-direction: column;
                justify-content: flex-start;
                .top{
                    background: red;
                    width: setnum(992);
                    height: setnum(558);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 3;
                }
                .cardbox{
                    width: setnum(882);
                    box-sizing: border-box;
                    background: #FFF;
                    z-index: 4;
                    padding: setnum(40) setnum(40);
                    .headerTitle{
                        margin-bottom: setnum(40);
                        h1{
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: bold;
                            font-size: setnum(56);
                            line-height: setnum(80);
                            color: #1A1A1A;
                            text-align: left;
                        }

                    }
                    .cardMain{
                        @include boxflex();
                        justify-content: flex-start;
                        //align-items: baseline;
                        align-items: initial;
                        margin-bottom: setnum(80);
                        .mleft{
                            margin-right: setnum(41);
                            .mrdatatime{
                                width: setnum(105);
                                .mrdyd{
                                    width: setnum(105);
                                    height: setnum(105);
                                    background: #1A1A1A;
                                    text-align: center;
                                    @include boxflex();
                                    flex-direction: column;
                                    justify-content: center;
                                    margin-bottom: setnum(16);
                                    p:first-child{
                                        font-family: Lato;
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: setnum(12);
                                        line-height: setnum(16);
                                        text-align: center;
                                        color: #FFFFFF;
                                        margin-bottom: setnum(8);
                                    }
                                    p:last-child{
                                        font-family: Lato;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: setnum(24);
                                        line-height: setnum(30);
                                        text-align: center;
                                        color: #FFFFFF;
                                    }

                                }
                                .mrtitle{
                                    p{
                                        font-family: Lato;
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: setnum(14);
                                        line-height: setnum(22);
                                        text-align: center;
                                        color: #B0A27C;
                                    }
                                }
                            }
                        }
                        .mright{
                            width: setnum(630);
                            padding-top: setnum(30);
                            box-sizing: border-box;
                            p{
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: normal;
                                font-size: setnum(16);
                                line-height: setnum(32);
                                color: #1A1A1A;
                                opacity: 0.8;
                                margin-bottom: setnum(40);
                            }
                            p:last-child{
                                margin-bottom: setnum(0);
                            }
                        }
                    }

                }

            }
            .cardFooter{
                width: setnum(992);
                .line{
                    width: 100%;
                    height: setnum(1);
                    opacity: 0.1;
                    background: #1A1A1A;
                    margin-bottom: setnum(56);
                }
                .tabselect{
                    @include boxflex();
                    .previous, .next, .backtolist{
                        @include boxflex();
                        justify-content: center;
                        cursor: pointer;
                        .icon{
                            width: setnum(24);
                            height: setnum(24);
                            display: block;
                            @include boxflex();
                            justify-content: center;
                            img{
                                width: setnum(24);
                                height: setnum(24);
                            }
                        }
                        span{
                            font-family: Lato;
                            font-style: normal;
                            font-weight: bold;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            color: #1A1A1A;
                        }
                    }

                    .previous, .next{
                        .scrollAnimation{
                            height: setnum(24);
                            min-height: 24px;
                            overflow: hidden;
                            cursor: pointer;
                            .scrollText{
                                transition: all 0.5s;
                                transform: translate(0%, 0%);
                                .row{
                                    width: auto;
                                    @include boxflex();
                                    justify-content: center;
                                    cursor: pointer;
                                }
                            }
                            .scrollText:hover{
                                transform: translate(0%, -58%);
                            }
                        }
                    }

                    .backtolist{
                        .scrollAnimation{
                            //width: setnum(120);
                            height: setnum(32);
                            min-height: 32px;
                            overflow: hidden;
                            cursor: pointer;
                            padding-top: setnum(6);
                            .scrollText{
                                transition: all 0.5s;
                                transform: translate(0%, 0%);
                                span{
                                    display: block;
                                    font-family: Lato;
                                    font-style: normal;
                                    font-weight: bold;
                                    font-size: setnum(16);
                                    line-height: setnum(32);
                                    text-align: center;
                                    color: #1A1A1A;
                                    cursor: pointer;
                                }
                                span:first-child{
                                    margin-bottom: setnum(10);
                                }
                            }
                            .scrollText:hover{
                                transform: translate(0%, -58%);
                            }
                        }
                    }

                    .active{
                        color: #1A1A1A;
                        opacity: 0.3;
                    }
                }
            }
        }
    }
}

.normalh5{
    background: #FFF;
    width: 100%;
    overflow: hidden;
    @include boxflex();
    justify-content: center;
    position: relative;
    :global{
        .textanimate{
            height: setnum(68);
            position: absolute;
            top: setnum(126);
            left: setnum(24);
            z-index: 0;
        }
        .content{
            z-index: 1;
            padding: setnum(166) 0 0 0;
            @include boxflex();
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .info{
                position: relative;
                padding-top: setnum(160);
                @include boxflex();
                flex-direction: column;
                justify-content: center;
                .top{
                    background: red;
                    width: 100%;
                    height: setnum(230);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 3;
                }
                .cardbox{
                    width: setnum(390);
                    background: transparent;
                    z-index: 4;
                    padding: setnum(24) setnum(24) setnum(24) 0;
                    .headerTitle{
                        padding: setnum(24) setnum(24) setnum(12)  setnum(24);
                        background: #FFF;
                        h1{
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: bold;
                            font-size: setnum(32);
                            line-height: setnum(40);
                            color: #1A1A1A;
                        }
                    }
                    .cardMain{
                        @include boxflex();
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: baseline;
                        padding: setnum(24) setnum(24);
                        background: #FFF;
                        .mrdatatime{
                            margin-bottom: setnum(32);
                            p{
                                font-family: Lato;
                                font-style: normal;
                                font-weight: normal;
                                font-size: setnum(13);
                                line-height: setnum(20);
                                text-align: left;
                                color: #B0A27C;
                            }
                            p:first-child{
                                margin-bottom: setnum(4);
                            }
                            p:last-child{
                                color: #1A1A1A;
                                opacity: 0.6;
                            }
                        }
                        .mright{
                            p{
                                font-family: Noto Sans SC;
                                font-style: normal;
                                font-weight: normal;
                                font-size: setnum(16);
                                line-height: setnum(28);
                                color: #1A1A1A;
                                opacity: 0.8;
                                text-align: justify;
                                margin-bottom: setnum(24);
                            }
                            p:last-child{
                                margin-bottom: setnum(0);
                            }
                        }
                    }

                }

            }
            .cardFooter{
                width: 100%;
                padding: 0 setnum(40);
                .line{
                    margin: 0 setnum(24);
                    height: setnum(1);
                    opacity: 0.1;
                    background: #1A1A1A;
                    margin-bottom: setnum(56);
                }
                .tabselect{
                    @include boxflex();
                    margin: 0 setnum(24);
                    .previous, .next, .backtolist{
                        @include boxflex();
                        justify-content: center;
                        cursor: pointer;
                        .icon{
                            width: setnum(24);
                            height: setnum(24);
                            display: block;
                            @include boxflex();
                            justify-content: center;
                            img{
                                width: setnum(24);
                                height: setnum(24);
                            }
                        }
                        span{
                            font-family: Lato;
                            font-style: normal;
                            font-weight: bold;
                            font-size: setnum(16);
                            line-height: setnum(32);
                            color: #1A1A1A;
                        }
                    }
                    .active{
                        color: #1A1A1A;
                        opacity: 0.3;
                    }
                }
            }


        }


    }
}