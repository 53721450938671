@import '../../global.module';


.normalMax{
    :global{
        .gamesBorderTextBox{
            width: setnum(2202);
            height: setnum($hMax);
            margin-left: setnum(-175);
            margin-bottom: setnum(50);
        }
        .content{
            padding: 0 0 0 setnum(480);
            margin-bottom: setnum(32);
        }
        .cardinfo{
            @include boxflex();
            justify-content: center;
            .cleft{
                width: setnum(485);
                height: setnum(520);
                box-sizing: border-box;
                background: #FFF;
                .cdesc{
                    padding: setnum(108) setnum(40);
                    .ctitle{
                        height: setnum(40);
                        font-weight: 500;
                        font-size: setnum(32);
                        line-height: setnum(40);
                        color: #1A1A1A;
                        margin-bottom: setnum(32);
                    }
                    .csubtext{
                        margin-bottom: setnum(24);
                        @include boxflex();
                        justify-content: flex-start;
                        align-items: baseline;

                        span{
                            height: setnum(22);
                            font-size: setnum(14);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: 500;
                            color: #1A1A1A;
                            display: block;
                        }
                        span:first-child{
                            width: setnum(52);
                            opacity: 0.6;
                            color: #1A1A1A;
                            margin-right: setnum(20);
                        }
                        span:last-child{
                            width: setnum(366);
                            color: #1A1A1A;
                            height: auto;
                        }
                        .ellipsis{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //多行在这里修改数字即可
                            /* autoprefixer: ignore next */
                            -webkit-box-orient: vertical;
                        }
                    }
                    .csubtext:last-child{
                        margin-bottom: setnum(0);
                    }
                }
            }
            .cright{
                width: setnum(715);
                height: setnum(520);
                position: relative;
                box-sizing: border-box;
                .no{
                    position: absolute;
                    top: setnum(52);
                    right: setnum(-61);
                    pointer-events: none;
                    span{
                        height: setnum(20);
                        font-size: setnum(14);
                        line-height: setnum(20);
                        color: #FFFFFF;
                        font-weight: 500;
                    }
                    span:first-child{
                        color: #FFFFFF;
                        margin-right: setnum(16);
                    }
                    span:nth-child(2){
                        color: #FFFFFF;
                    }
                    span:last-child{
                        color: #1A1A1A;
                    }
                }
                .pressm{
                    width: setnum(100);
                    height: setnum(100);
                    border: setnum(2) solid #FFFFFF;
                    box-sizing: border-box;
                    border-radius: 100%;
                    text-align: center;
                    line-height: setnum(100);
                    position: absolute;
                    top: setnum(32);
                    right: setnum(32);
                    cursor: pointer;
                    span{
                        font-weight: 500;
                        font-size: setnum(14);
                        text-align: center;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}

.normal{
    :global{
        .gamesBorderTextBox{
            width: setnum(1512.7);
            height: setnum(134.01);
            margin-left: setnum(-49);
            margin-bottom: setnum(50);
        }
        .content{
            padding: 0 0 0 setnum(304);
            margin-bottom: setnum(32);
        }
        .cardinfo{
            @include boxflex();
            justify-content: center;
            .cleft{
                width: setnum(450);
                height: setnum(400);
                box-sizing: border-box;
                background: #FFF;
                .cdesc{
                    padding: setnum(108) setnum(35);
                    .ctitle{
                        height: setnum(40);
                        font-weight: 500;
                        font-size: setnum(32);
                        line-height: setnum(40);
                        color: #1A1A1A;
                        margin-bottom: setnum(32);
                    }
                    .csubtext{
                        margin-bottom: setnum(24);
                        @include boxflex();
                        justify-content: flex-start;
                        align-items: baseline;
                        span{
                            height: setnum(22);
                            font-size: setnum(14);
                            font-family: Lato;
                            font-style: normal;
                            font-weight: 500;
                            color: #1A1A1A;
                            display: block;
                        }
                        span:first-child{
                            width: setnum(52);
                            opacity: 0.6;
                            color: #1A1A1A;
                            margin-right: setnum(20);
                        }
                        span:last-child{
                            width: setnum(307);
                            color: #1A1A1A;
                            height: auto;
                        }
                        .ellipsis{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //多行在这里修改数字即可
                            /* autoprefixer: ignore next */
                            -webkit-box-orient: vertical;
                        }
                    }
                    .csubtext:last-child{
                        margin-bottom: setnum(0);
                    }
                }
            }
            .cright{
                width: setnum(550);
                height: setnum(400);
                position: relative;
                box-sizing: border-box;
                .no{
                    position: absolute;
                    //top: setnum(52);
                    //right: setnum(-61);
                    top: setnum(20);
                    right: setnum(20);
                    span{
                        height: setnum(20);
                        font-size: setnum(14);
                        line-height: setnum(20);
                        color: #FFFFFF;
                        font-weight: 500;
                    }
                    span:first-child{
                        color: #FFFFFF;
                        margin-right: setnum(16);
                    }
                    span:nth-child(2){
                        color: #FFFFFF;
                    }
                    span:last-child{
                        color: #FFFFFF;
                    }
                }
                .pressm{
                    width: setnum(100);
                    height: setnum(100);
                    border: setnum(2) solid #FFFFFF;
                    box-sizing: border-box;
                    border-radius: 100%;
                    text-align: center;
                    line-height: setnum(100);
                    position: absolute;
                    top: setnum(32);
                    right: setnum(32);
                    z-index: 2;
                    cursor: pointer;
                    span{
                        font-weight: 500;
                        font-size: setnum(14);
                        text-align: center;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}

.normalH5{
    :global{
        .gamesBorderTextBox{
            width: setnum(472);
            height: setnum(68);
            margin-left: setnum(-23);
            margin-bottom: setnum(16);
        }
        .content{
            padding: 0 setnum(24);
            margin-bottom: setnum(72);
        }
        .cardinfo{
            @include boxflex();
            flex-direction: column-reverse;
            justify-content: center;
            .cleft{
                width: setnum(366);
                background: #FFF;
                .cdesc{
                    padding: setnum(24);
                    .ctitle{
                        height: setnum(28);
                        font-weight: 500;
                        font-size: setnum(20);
                        line-height: setnum(28);
                        color: #1A1A1A;
                        margin-bottom: setnum(24);
                    }
                    .csubtext{
                        margin-bottom: setnum(24);
                        @include boxflex();
                        justify-content: flex-start;
                        align-items: baseline;
                        span{
                            height: setnum(22);
                            font-size: setnum(16);
                            color: #1A1A1A;
                            display: block;
                        }
                        span:first-child{
                            width: setnum(52);
                            opacity: 0.6;
                            color: #1A1A1A;
                            margin-right: setnum(20);
                        }
                        span:last-child{
                            width: setnum(307);
                            color: #1A1A1A;
                            height: auto;
                        }
                        /*.ellipsis{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; //多行在这里修改数字即可
                            -webkit-box-orient: vertical;
                        }*/
                    }
                    .csubtext:last-child{
                        margin-bottom: setnum(0);
                    }
                }
            }
            /*.mapBox{
                width: setnum(366);
                height: setnum(366);
                overflow: auto;

            }*/
            .cright{
                //width: setnum(580);
                width: setnum(366);
                height: setnum(422);
                position: relative;
                overflow: auto;
                .no{
                    position: absolute;
                    top: setnum(52);
                    right: setnum(-61);
                    span{
                        height: setnum(20);
                        font-size: setnum(14);
                        line-height: setnum(20);
                        color: #FFFFFF;
                        font-weight: 500;
                    }
                    span:first-child{
                        color: #FFFFFF;
                        margin-right: setnum(16);
                    }
                    span:nth-child(2){
                        color: #FFFFFF;
                    }
                    span:last-child{
                        color: #1A1A1A;
                    }
                }
                .pressm{
                    width: setnum(100);
                    height: setnum(100);
                    border: setnum(2) solid #FFFFFF;
                    box-sizing: border-box;
                    border-radius: 100%;
                    text-align: center;
                    line-height: setnum(100);
                    position: absolute;
                    top: setnum(20);
                    right: setnum(20);
                    span{
                        font-weight: 500;
                        font-size: setnum(14);
                        text-align: center;
                        color: #FFFFFF;
                    }
                }
            }
            /* 隐藏滚动条 */
            .mapBox::-webkit-scrollbar {
                display: none;
            }

        }
    }
}