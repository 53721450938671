@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;700;900&family=Open+Sans:wght@300;400;600;700;800&display=swap');

$math: 100; // 变量

// PC
@function px2vwp($px) {
  @return $px * 100vw / 1920;
}
// H5
@function px2vwm($px) {
  @return $px * 100vw / 414;
}

// 设置值函数
@function setnum($total){
    $num: 100;
    @return $total / $num + rem;
}

// flex 混入函数
@mixin boxflex() {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

// 超出两个字显示...
@mixin textEllipsis($num){
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $num;
  -webkit-box-orient: vertical;
}

@mixin whiteSpaceNowrap() {
  white-space: nowrap;
}

// 背景模糊
// .demo{filter: blur(2px);}

// 透明色
$bgTran: transparent; // transparent;
$tst: all 0.5s;
$hMax: 176.76;

$darkYellow: #b0a27c;

*{
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

body{
  font-size: setnum(14);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  display: block;
  margin-block-start: initial;
  margin-block-end: initial;
  margin-inline-start: initial;
  margin-inline-end: initial;
}

h1,h2,h3,h4,h5,h6 {
  display: block;
  font-size: initial;
  margin-block-start: initial;
  margin-block-end: initial;
  margin-inline-start: initial;
  margin-inline-end: initial;
  font-weight: bold;
}
ul,li{
  list-style: none;
}


@mixin AnimationFades(){
  0% {
    stroke-opacity: 0;
    stroke-dasharray: 0;// 0;
  }
  //96.07843137254902% {
  55% {
    stroke-opacity: 1;
    //stroke-dasharray: 965 967;
    stroke-dasharray: 997 1000;
  }
  100% {
    stroke-opacity: 1;
    //stroke-dasharray: 965 967;
    stroke-dasharray: 1000 997;
  }
}

@mixin SvgPaths($fillBgColor, $strokeBorderColor){
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: $fillBgColor; // transparent
  stroke: $strokeBorderColor; // rgba(204, 204, 204, 1)
  stroke-miterlimit: 10;
}

@keyframes animationFade {
  @include AnimationFades();
}

@mixin SvgPathAnimations($strokeDashoffset){
  stroke-dasharray:0;
  //stroke-dashoffset: 166;
  stroke-dashoffset: $strokeDashoffset; // 10; // 166
  animation: animationFade 6000ms ease-in-out 0ms forwards;
}
// SVG 动画 end