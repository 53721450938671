@import '../../global.module';

.normal{
    position: relative;
    width: 100%;
    :global{

    }
}

.normalM{
    position: relative;
    width: 100%;
    :global{

    }
}